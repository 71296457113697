import { Grid, GridCol, Heading2, Stack, Subtitle2 } from '@phx/design-system';
import type { SemanticColorVariants } from '@phx/design-system/framework';
import {
    ComparePricesIllustration,
    ConsumersIllustration,
    DocumentsIllustration,
    IllustrationCard,
    PharmacyIllustration,
    PrescriptionIllustration,
    ReportsIllustration,
    ShieldIllustration,
} from '@phx/myphx-lib';
import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import type { IllustrationProps } from '../../../../../../../libraries/myphx-lib/dist/components/illustration/Illustration';
import { getAbsoluteRoute } from '../../../../util';

interface FAQCardProps {
    illustration: (props: IllustrationProps) => ReactNode;
    subtitle: string;
    onClick: () => void;
    color: SemanticColorVariants;
}

export const FaqSection = () => {
    const { t } = useTranslation(['help-center-locales/help-center']);

    const navigate = useNavigate();

    const faqCards: FAQCardProps[] = [
        {
            illustration: PharmacyIllustration,
            subtitle: t('helpCenter.faq.about'),
            onClick: () =>
                navigate(getAbsoluteRoute('helpCenter.about'), {
                    state: { header: t('helpCenter.faq.about') },
                }),
            color: 'primary',
        },
        {
            illustration: DocumentsIllustration,
            subtitle: t('helpCenter.faq.employer'),
            onClick: () =>
                navigate(getAbsoluteRoute('helpCenter.employer'), {
                    state: { header: t('helpCenter.faq.employer') },
                }),
            color: 'saving',
        },
        {
            illustration: ReportsIllustration,
            subtitle: t('helpCenter.faq.settings'),
            onClick: () =>
                navigate(getAbsoluteRoute('helpCenter.settings'), {
                    state: { header: t('helpCenter.faq.settings') },
                }),
            color: 'error',
        },
        {
            illustration: ComparePricesIllustration,
            subtitle: t('helpCenter.faq.costsAndSavings'),
            onClick: () =>
                navigate(getAbsoluteRoute('helpCenter.costsAndSavings'), {
                    state: { header: t('helpCenter.faq.costsAndSavings') },
                }),
            color: 'warning',
        },
        {
            illustration: PrescriptionIllustration,
            subtitle: t('helpCenter.faq.prescriptionManagement'),
            onClick: () =>
                navigate(
                    getAbsoluteRoute('helpCenter.prescriptionManagement'),
                    {
                        state: {
                            header: t('helpCenter.faq.prescriptionManagement'),
                        },
                    }
                ),
            color: 'info',
        },
        {
            illustration: ConsumersIllustration,
            subtitle: t('helpCenter.faq.familyCare'),
            onClick: () =>
                navigate(getAbsoluteRoute('helpCenter.familyCare'), {
                    state: { header: t('helpCenter.faq.familyCare') },
                }),
            color: 'error',
        },
        {
            illustration: ShieldIllustration,
            subtitle: t('helpCenter.faq.privacySecurity'),
            onClick: () =>
                navigate(getAbsoluteRoute('helpCenter.privacySecurity'), {
                    state: { header: t('helpCenter.faq.privacySecurity') },
                }),
            color: 'notice',
        },
    ];

    return (
        <Stack>
            <Heading2>{t('helpCenter.subheader.faq')}</Heading2>
            <Grid gutter="xs">
                {faqCards.map(({ illustration, subtitle, onClick, color }) => (
                    <GridCol display="flex" span={6} key={subtitle}>
                        <IllustrationCard
                            illustrationPosition="top"
                            variant="outlined"
                            Illustration={illustration}
                            illustrationSize="xs"
                            onClick={onClick}
                            color={color}
                        >
                            <Subtitle2 ta="center">{subtitle}</Subtitle2>
                        </IllustrationCard>
                    </GridCol>
                ))}
            </Grid>
        </Stack>
    );
};
