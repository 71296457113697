import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Heading1, HorizontalDivider, Stack } from '@phx/design-system';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

import { getFragment } from '../../graphql';
import {
    type CashOfferInfoFragment,
    type DigitallyReceivedPrescriberOrderInfoFragment,
    GetPrescriberOrderDocument,
    type InsuranceOfferInfoFragment,
    type NonPriceableOfferInfoFragment,
    SendOfferDocument,
} from '../../graphql/generated/graphql';
import { getAbsoluteRoute } from '../../util';
import { ShoppingHeader } from '../cabinet/shopping-header/ShoppingHeader';
import { NoPricing } from '../common/pricing';
import { Pricing } from '../common/pricing/Pricing';

import { PatientProductDetails } from './components/PatientProductDetails';
import { PharmacyProviderDetails } from './components/PharmacyProviderDetails';

export type PreviewDetailsProps = {
    offer:
        | CashOfferInfoFragment
        | InsuranceOfferInfoFragment
        | NonPriceableOfferInfoFragment;
    prescriberOrder: DigitallyReceivedPrescriberOrderInfoFragment;
};

export const PreviewDetails = ({
    offer,
    prescriberOrder,
}: PreviewDetailsProps) => {
    const { t } = useTranslation();

    const patient = getFragment(prescriberOrder.patient);
    const product = getFragment(offer.product);

    const [getUpdatedPrescriberOrder] = useLazyQuery(
        GetPrescriberOrderDocument,
        {
            fetchPolicy: 'network-only',
        }
    );

    const [sendOffer, { data, loading, error, called }] = useMutation(
        SendOfferDocument,
        {
            onCompleted: () => {
                setTimeout(() => {
                    getUpdatedPrescriberOrder({
                        variables: { prescriberOrderId: prescriberOrder.id },
                    });
                }, 10000);
            },
        }
    );

    if (called && error && !loading && !data?.sendOffer?.success) {
        return <Navigate to={getAbsoluteRoute('error')} />;
    }

    if (!loading && data?.sendOffer?.success) {
        return (
            <Navigate
                to={getAbsoluteRoute('orders.prescriberOrderId.confirmation', {
                    prescriberOrderId: prescriberOrder.id,
                })}
            />
        );
    }

    const priceSection =
        offer.__typename === 'CashOffer' ||
        offer.__typename === 'InsuranceOffer' ? (
            <Pricing offer={offer} />
        ) : (
            <NoPricing />
        );

    return (
        <Stack gap="lg">
            <ShoppingHeader>
                <PatientProductDetails
                    patientName={patient.fullName}
                    productDescription={product.description}
                    quantity={offer.quantity}
                />
            </ShoppingHeader>

            <HorizontalDivider variant="full" />

            <Stack gap="xxl">
                <Stack gap="md">
                    <Heading1>{t('previewDetails.title')}</Heading1>
                    {priceSection}
                </Stack>

                <PharmacyProviderDetails offer={offer} />
            </Stack>

            <Button
                size="lg"
                loading={loading}
                onClick={() => sendOffer({ variables: { offerId: offer.id } })}
            >
                {t('previewDetails.sendToPharmacy')}
            </Button>
        </Stack>
    );
};
