import { useCallback, useState } from 'react';
const ldFlagStorageKey = 'overriddenLDflags';
export const useFlagOverrides = () => {
    const [overriddenFlags, setOverriddenFlags] = useState(() => {
        const previousValue = localStorage.getItem(ldFlagStorageKey);
        if (previousValue) {
            return JSON.parse(previousValue);
        }
        return {};
    });
    const setFlagOverride = useCallback(({ key, value }) => {
        const updatedFlags = { ...overriddenFlags, [key]: value };
        setOverriddenFlags(updatedFlags);
        localStorage.setItem(ldFlagStorageKey, JSON.stringify(updatedFlags));
    }, [overriddenFlags]);
    const clearFlagOverrides = useCallback(() => {
        setOverriddenFlags({});
        localStorage.setItem(ldFlagStorageKey, JSON.stringify({}));
    }, []);
    return { overriddenFlags, setFlagOverride, clearFlagOverrides };
};
