import { AuthorizedOutlet } from '@phx/auth/client';
import { Outlet, type RouteObject } from 'react-router-dom';

import routes from '../app-routes';

import OrderConfirmationRoute from './OrderConfirmationRoute';
import OrderDetailsRoute from './OrderDetailsRoute';

const ordersRoutes: RouteObject[] = [
    {
        path: routes.orders.root,
        element: <AuthorizedOutlet />,
        children: [
            {
                path: routes.orders.prescriberOrderId.root,
                element: <Outlet />,
                children: [
                    {
                        path: routes.orders.prescriberOrderId.confirmation,
                        element: <OrderConfirmationRoute />,
                    },
                    {
                        path: routes.orders.prescriberOrderId.details,
                        element: <OrderDetailsRoute />,
                    },
                ],
            },
        ],
    },
];

export default ordersRoutes;
