import { Heading1, Stack } from '@phx/design-system';
import { useTranslation } from 'react-i18next';

import { ComparePricesCard } from '../../components/start-saving/ComparePricesCard';
import { Faq } from '../../components/start-saving/Faq';
import { Steps } from '../../components/start-saving/Steps';

const StartSavingRoute = () => {
    const { t } = useTranslation();

    return (
        <Stack gap="xl">
            <Stack gap="lg">
                <Heading1>{t('startSavingPage.title')}</Heading1>
                <Steps />
            </Stack>
            <ComparePricesCard />
            <Faq />
        </Stack>
    );
};

export default StartSavingRoute;
