import formatToLocalizedDate from './format-to-localized-date';

export const formatDateString = (dateString: string, locale?: string) => {
    const date = new Date(dateString);

    return formatToLocalizedDate(
        date,
        { dateStyle: 'short' },
        locale ?? 'en-US'
    );
};
