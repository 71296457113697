import { makeVar } from '@apollo/client';

export function makePersistentVar<T>(initialValue: T, key: string) {
    const variable = makeVar<T>(initialValue);

    function handleOnChangeEvent(data: T) {
        localStorage.setItem(key, JSON.stringify(data));
        variable.onNextChange(handleOnChangeEvent);
    }

    function restore() {
        const previousValue = localStorage.getItem(key);
        if (previousValue) {
            variable(JSON.parse(previousValue));
        }
    }

    restore();
    variable.onNextChange(handleOnChangeEvent);
    return variable;
}
