import { Accordion, Body1, Heading2, Stack } from '@phx/design-system';
import { constants } from '@phx/design-system/framework';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const Faq = () => {
    const { t } = useTranslation();

    const faqs = useMemo(() => {
        const faqObjects = t('startSavingPage.faq.items', {
            returnObjects: true,
            tm: constants.unicode.TRADEMARK,
        }) as Array<{ title: string; content: string }>;

        if (!Array.isArray(faqObjects)) {
            return [];
        }

        return faqObjects.map((faq) => ({
            key: faq.title,
            header: faq.title,
            children: <Body1>{faq.content}</Body1>,
        }));
    }, [t]);

    return (
        <Stack gap="sm">
            <Heading2>{t('startSavingPage.faq.heading')}</Heading2>
            <Accordion items={faqs} />
        </Stack>
    );
};
