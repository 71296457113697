import { readProviderDescription } from './read-provider-description';

export const mailOrderProviderFields = {
    description: {
        read: readProviderDescription,
    },
};

export const pharmacyProviderFields = {
    description: {
        read: readProviderDescription,
    },
};
