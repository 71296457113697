import { AuthorizedOutlet } from '@phx/auth/client';
import { Outlet, type RouteObject } from 'react-router-dom';

import routes from '../app-routes';

import { TransferLocationRoute } from './TransferLocationRoute';
import { TransferRoute } from './TransferRoute';
import { TransferSubmittedRoute } from './TransferSubmittedRoute';
import { VerifyTransferRoute } from './VerifyTransferRoute';

export const transferRoutes: RouteObject[] = [
    {
        path: routes.transfer.root,
        element: <AuthorizedOutlet />,
        children: [
            {
                index: true,
                element: <TransferRoute />,
            },
            {
                path: routes.transfer.location.root,
                element: <Outlet />,
                children: [
                    {
                        index: true,
                        element: <TransferLocationRoute />,
                    },

                    {
                        path: routes.transfer.location.verify.root,
                        element: <Outlet />,
                        children: [
                            {
                                index: true,
                                element: <VerifyTransferRoute />,
                            },
                            {
                                path: routes.transfer.location.verify.submitted,
                                element: <TransferSubmittedRoute />,
                            },
                        ],
                    },
                ],
            },
        ],
    },
];
