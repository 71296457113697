import {
    Body1,
    Flex,
    IconFilter,
    Stack,
    TextButton,
    useNavBar,
} from '@phx/design-system';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ProviderOffersInfoFragment } from '../../../graphql/generated/graphql';
import { FilterCover } from '../../filter-cover/FilterCover';

import { NoProviderOffers } from './NoProviderOffers';
import { ProviderOffersCard } from './ProviderOffersCard';

export const ProviderOffersCardList = ({
    providerOffers,
    onSelect,
    pickUp,
    filterOpen,
    openFilter,
    closeFilter,
}: {
    providerOffers: ProviderOffersInfoFragment[];
    onSelect: (id: string, providerId?: string) => void;
    pickUp?: boolean;
    filterOpen: boolean;
    openFilter: () => void;
    closeFilter: () => void;
}) => {
    const { setBackOverrideState } = useNavBar();

    const { t } = useTranslation();

    const [offers, setOffers] =
        useState<ProviderOffersInfoFragment[]>(providerOffers);
    const [filters, setFilters] = useState<number>(0);

    const handleSortedOffers = (sortedOffers: ProviderOffersInfoFragment[]) => {
        setOffers(sortedOffers);
    };

    const handleActiveFilters = (activeFilters: number) => {
        setFilters(activeFilters);
    };

    const filterText = pickUp
        ? t('providerOffers.filter.pickUp')
        : t('providerOffers.filter.delivery');

    const handleBack = useCallback(() => {
        closeFilter();
    }, [closeFilter]);

    useEffect(() => {
        if (filterOpen) {
            setBackOverrideState({
                overrideFn: handleBack,
            });

            return () => setBackOverrideState({ overrideFn: null });
        }
    }, [handleBack, filterOpen]);

    return (
        <Stack gap="md">
            <Flex justify="space-between" align="center">
                <Body1>
                    {t('providerOffers.pharmacies', {
                        count: offers.length,
                    })}
                </Body1>
                <TextButton onClick={openFilter} LeftIcon={IconFilter}>
                    {`${filterText} ${filters > 0 ? t('providerOffers.filter.filters_with_count', { filters }) : t('providerOffers.filter.filters')}`}
                </TextButton>
            </Flex>
            <Stack gap="sm" role="list">
                {offers.length ? (
                    offers.map((offer) => {
                        return (
                            <ProviderOffersCard
                                key={`card-${offer.id}`}
                                providerOffers={offer}
                                onSelected={onSelect}
                                role="listitem"
                            />
                        );
                    })
                ) : (
                    <NoProviderOffers />
                )}
            </Stack>
            <FilterCover
                providerOffers={providerOffers}
                pickUp={pickUp}
                isOpen={filterOpen}
                onClose={closeFilter}
                onSortedOffersChange={handleSortedOffers}
                onActiveFiltersChange={handleActiveFilters}
            />
        </Stack>
    );
};
