import { Body1, Button, Heading2, Stack } from '@phx/design-system';
import { useTranslation } from 'react-i18next';

import type { PrescriberInfoFragment } from '../../../../graphql/generated/graphql';
import { Prescriber } from '../../../prescriber/Prescriber';

type AltsPrescriberSectionProps = {
    prescriber: PrescriberInfoFragment;
};

export const AltsPrescriberSection = ({
    prescriber,
}: AltsPrescriberSectionProps) => {
    const { t } = useTranslation();

    return (
        <Stack gap="sm">
            <Heading2>{t('alternatives.newPrescription.title')}</Heading2>
            <Body1>{t('alternatives.newPrescription.description')}</Body1>
            <Prescriber prescriber={prescriber} />
            <Button
                onClick={() =>
                    (location.href = `tel:${prescriber.phoneNumber}`)
                }
            >
                {t('alternatives.newPrescription.callPrescriber')}
            </Button>
        </Stack>
    );
};
