import { Body1 } from '@phx/design-system';

type HighlightTextProps = {
    text: string;
    substring: string;
};

export const HighlightText = ({ text, substring }: HighlightTextProps) => {
    let parts;
    try {
        parts = text.split(new RegExp(`(${substring})`, 'gi'));
    } catch {
        return <Body1>{text}</Body1>;
    }

    return (
        <>
            {parts.map((part, i) => (
                <Body1
                    color={
                        part.toLowerCase() === substring.toLowerCase()
                            ? 'primary'
                            : 'gray'
                    }
                    key={`${text}-result-${i}`}
                    bold={part.toLowerCase() === substring.toLowerCase()}
                >
                    {part}
                </Body1>
            ))}
        </>
    );
};
