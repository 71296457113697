import { Body2, Box, Button, Flex, Subtitle2 } from '@phx/design-system';
import { useDisclosure } from '@phx/design-system/hooks';
import { MedFormIcon } from '@phx/myphx-lib';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getFragment } from '../../../graphql';
import type {
    DigitallyReceivedPrescriberOrderBasicInfoFragment,
    UnclaimedPrescriberOrderInfoFragment,
} from '../../../graphql/generated/graphql';
import { useFlags } from '../../../hooks/use-flags';
import { getAbsoluteRoute } from '../../../util';
import {
    type BasicPrescriberOrderFragment,
    getMedicationDescription,
    getOrderQuantity,
    getPrescribedProductFragment,
    isUnclaimedPrescriberOrder,
} from '../../../util/cabinet/prescriber-orders';
import DrugDetails from '../../common/DrugDetails';

import { UnclaimedOrderInfoModal } from './UnclaimedOrderInfoModal';

export type MedicationOrderProps = {
    order: BasicPrescriberOrderFragment;
};

export const MedicationOrder = ({ order }: MedicationOrderProps) => {
    const { drugDetailsSplitEnabled } = useFlags();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [
        showUnclaimedModal,
        { open: openUnclaimedModal, close: closeUnclaimedModal },
    ] = useDisclosure(false);

    const fillOrder = useCallback(() => {
        const med = order as DigitallyReceivedPrescriberOrderBasicInfoFragment;
        const navigationId = med.drxId ?? med.id;

        navigate(
            getAbsoluteRoute('cabinet.rxId.root', {
                prescriptionId: navigationId,
            })
        );
    }, [order, navigate]);

    const verifyOrder = useCallback(() => {
        const med = order as UnclaimedPrescriberOrderInfoFragment;
        const navigationId = med.drxId ?? med.id;

        navigate(
            getAbsoluteRoute('cabinet.rxId.verifyPatient', {
                prescriptionId: navigationId,
            })
        );
    }, [order, navigate]);

    const digitallyPrescribedProductId =
        order.__typename === 'DigitallyReceivedPrescriberOrder'
            ? order.id
            : undefined;

    const prescribedProduct = getFragment(getPrescribedProductFragment(order));
    const isUnclaimed = isUnclaimedPrescriberOrder(order);
    const description = getMedicationDescription(order);

    const clickHandler = useMemo(() => {
        if (isUnclaimed) {
            return openUnclaimedModal;
        }

        if (digitallyPrescribedProductId) {
            return () =>
                navigate(
                    getAbsoluteRoute('orders.prescriberOrderId.details', {
                        prescriberOrderId: digitallyPrescribedProductId,
                    }),
                    { state: { prev: 'history' } }
                );
        }

        return undefined;
    }, [digitallyPrescribedProductId, isUnclaimed, openUnclaimedModal]);

    if (!description) {
        return null;
    }

    return (
        <Flex gap="xs" justify="space-between" align="start" role="listitem">
            <Flex gap="xs">
                <MedFormIcon form={prescribedProduct?.formValue} />
                <Box
                    style={{ cursor: clickHandler ? 'pointer' : 'auto' }}
                    onClick={clickHandler}
                >
                    <Box data-testid="medication-entry-medication-description">
                        <Subtitle2>
                            {drugDetailsSplitEnabled
                                ? prescribedProduct?.name
                                : description}
                        </Subtitle2>
                    </Box>
                    {drugDetailsSplitEnabled && prescribedProduct ? (
                        <DrugDetails
                            product={prescribedProduct}
                            quantity={getOrderQuantity(order)}
                        />
                    ) : null}
                    {isUnclaimed ? (
                        <Body2 data-testid="medication-entry-verify-medication-text">
                            {t('cabinet.medicationOrder.verifyPatient')}
                        </Body2>
                    ) : (
                        <Body2
                            data-testid={`medication-entry-patient-name-${order.patient.id}`}
                        >
                            {t('cabinet.medicationOrder.forName', {
                                name: getFragment(order.patient).fullName,
                            })}
                        </Body2>
                    )}
                </Box>
            </Flex>
            <Box>
                {isUnclaimed ? (
                    <Button
                        variant="outline"
                        onClick={verifyOrder}
                        size="sm"
                        data-testid="medication-entry-verify-button"
                    >
                        {t('cabinet.orders.verify')}
                    </Button>
                ) : order.__typename === 'DigitallyReceivedPrescriberOrder' ? (
                    <Button
                        variant="contained"
                        onClick={fillOrder}
                        size="sm"
                        data-testid="medication-entry-fill-button"
                    >
                        {t('cabinet.orders.fill')}
                    </Button>
                ) : undefined}
            </Box>
            <UnclaimedOrderInfoModal
                onClose={closeUnclaimedModal}
                verifyOrder={verifyOrder}
                visible={showUnclaimedModal}
            />
        </Flex>
    );
};
