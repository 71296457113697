import type {
    CashOfferInfoFragment,
    InsuranceOfferInfoFragment,
    ProviderOffersInfoFragment,
} from '../../graphql/generated/graphql';

import { getMinCost, getMinPlanPays } from './get-minimum-cost';

/**
 * Sorts an array of provider offers by the minimum amount that the insurance plan pays.
 *
 * @param offers An array of provider offers
 * @returns The sorted array of provider offers by the minimum plan pays amount
 */
export function sortOffersByPlanPays(offers: ProviderOffersInfoFragment[]) {
    return offers.toSorted((a, b) => {
        const aMinPlanPays = getMinPlanPays(
            a.offers as InsuranceOfferInfoFragment[]
        );
        const bMinPlanPays = getMinPlanPays(
            b.offers as InsuranceOfferInfoFragment[]
        );

        return aMinPlanPays - bMinPlanPays;
    });
}

/**
 * Sorts an array of provider offers by the lowest cost between cash and insurance offers.
 *
 * @param offers An array of provider offers
 * @returns The sorted array of provider offers by the lowest cost
 */
export function sortOffersByPrice(offers: ProviderOffersInfoFragment[]) {
    return offers.toSorted((a, b) => {
        const aMinCost = getMinCost(
            a.offers as (CashOfferInfoFragment | InsuranceOfferInfoFragment)[]
        );
        const bMinCost = getMinCost(
            b.offers as (CashOfferInfoFragment | InsuranceOfferInfoFragment)[]
        );

        return aMinCost - bMinCost;
    });
}

/**
 * Sorts an array of provider offers by distance.
 *
 * @param offers An array of provider offers
 * @returns The sorted array of provider offers by closest distance to furthest
 */
export function sortOffersByDistance(offers: ProviderOffersInfoFragment[]) {
    return offers.toSorted((a, b) => a.distance - b.distance);
}

/**
 * Sorts an array of provider offers by delivery time.
 *
 * @param offers An array of provider offers
 * @returns The sorted array of provider offers by fastest delivery time to slowest
 */
export function sortOffersByDeliveryTime(offers: ProviderOffersInfoFragment[]) {
    return offers; //TODO: Implement delivery time sorting when available
}
