import { AuthorizedRoute } from '@phx/auth/client';
import { Outlet, type RouteObject } from 'react-router-dom';

import routes from '../app-routes';

import { CabinetHistoryRoute } from './CabinetHistoryRoute';
import { CabinetRoute } from './CabinetRoute';
import patientRoutes from './patient';
import prescriptionRoutes from './prescription';

const cabinetRoutes: RouteObject[] = [
    {
        path: routes.cabinet.root,
        element: <Outlet />,
        children: [
            {
                index: true,
                element: (
                    <AuthorizedRoute>
                        <CabinetRoute />
                    </AuthorizedRoute>
                ),
            },
            {
                path: routes.cabinet.history,
                element: (
                    <AuthorizedRoute>
                        <CabinetHistoryRoute />
                    </AuthorizedRoute>
                ),
            },
            ...patientRoutes,
            ...prescriptionRoutes,
        ],
    },
];

export default cabinetRoutes;
