import {
    Body1,
    FilterSelection,
    FullBleed,
    Heading1,
    IconLocationOn,
    Stack,
} from '@phx/design-system';
import { useDisclosure } from '@phx/design-system/hooks';
import { useGeolocation } from '@phx/location-utils';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import type {
    MailOrderProviderInfoFragment,
    PharmacyProviderInfoFragment,
} from '../../graphql/generated/graphql';
import { useNumericSearchParam } from '../../hooks/use-numeric-search-param';
import { getAbsoluteRoute } from '../../util';
import { LocationPickerCover } from '../location-picker-cover';

import { LocationCardList } from './components/LocationCardList';

type TransferFromProps = {
    providerOffersId: string;
    offerId: string;
    providers: (PharmacyProviderInfoFragment | MailOrderProviderInfoFragment)[];
};

export const TransferFrom = ({
    providers,
    providerOffersId,
    offerId,
}: TransferFromProps) => {
    const { t } = useTranslation();

    const { geolocation } = useGeolocation();
    const [opened, { open, close }] = useDisclosure(false);

    const distance = useNumericSearchParam('within');
    const navigate = useNavigate();

    const onSelect = (id: string) => {
        navigate(
            getAbsoluteRoute('transfer.location.verify.root', {
                providerOffersId: providerOffersId,
                offerId: offerId,
                locationId: id,
            })
        );
    };

    return (
        <>
            <LocationPickerCover isOpen={opened} onClose={close} />
            <Stack gap="lg">
                <Stack gap="sm">
                    <Heading1>{t('transfer.transferFrom')}</Heading1>
                    <Body1>{t('transfer.transferFromDescription')}</Body1>
                </Stack>
                <FilterSelection
                    input={geolocation?.name ?? ''}
                    StartIcon={IconLocationOn}
                    onInputClick={open}
                    distance={`${distance} ${t('common.distance.distanceUnit')}`}
                />

                <FullBleed breakpoint="md" color="gray">
                    <LocationCardList
                        providers={providers}
                        onSelect={onSelect}
                    />
                </FullBleed>
            </Stack>
        </>
    );
};
