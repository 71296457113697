import type {
    CashOfferInfoFragment,
    InsuranceOfferInfoFragment,
} from '../../../graphql/generated/graphql';

import { MailOrderPricing } from './MailOrderPricing';
import { PickupPricing } from './PickupPricing';

type PharmacyTypes = 'MailOrderProvider' | 'PharmacyProvider';
const PricingByType: Record<
    PharmacyTypes,
    ({
        offer,
    }: {
        offer: CashOfferInfoFragment | InsuranceOfferInfoFragment;
    }) => JSX.Element | null
> = {
    MailOrderProvider: MailOrderPricing,
    PharmacyProvider: PickupPricing,
};

export const Pricing = ({
    offer,
}: {
    offer: CashOfferInfoFragment | InsuranceOfferInfoFragment;
}) => {
    if (!offer.pharmacyProvider.__typename) {
        return null;
    }
    const Component = PricingByType[offer.pharmacyProvider.__typename];
    return <Component offer={offer} />;
};
