import { ApplicationInsights, } from '@microsoft/applicationinsights-web';
function getCommonProperties(event) {
    return {
        /** Add basic details from window but allow them to be spread over if defined*/
        href: window.location.href,
        pathName: window.location.pathname,
        ...event?.properties,
        source: event.source,
    };
}
function logEvent(event, instance) {
    instance.trackEvent({
        ...event,
        properties: {
            ...getCommonProperties(event),
        },
    });
}
function logError(event, instance) {
    instance.trackException({
        ...event,
        properties: {
            ...getCommonProperties(event),
        },
    });
}
function logMetric(event, instance) {
    instance.trackMetric({
        ...event,
        properties: {
            ...getCommonProperties(event),
        },
    });
}
function logUnknownError(event, error, instance) {
    let exception;
    if (error instanceof Error) {
        exception = error;
    }
    else if (typeof error === 'string') {
        exception = new Error(error);
    }
    else {
        exception = new Error(`Unknown Error in ${event.source}`);
    }
    instance.trackException({
        ...event,
        properties: {
            ...getCommonProperties(event),
        },
        exception,
    });
}
export const logging = {
    logError,
    logUnknownError,
    logEvent,
    logMetric,
};
