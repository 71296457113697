// src/components/PwaOverlays.tsx
import {useContext as useContext2} from "react";

// src/components/AddContactOverlay.tsx
import {
Body1,
Box,
Button,
Cover,
Flex,
Heading1,
Modal,
Stack,
TextButton,
useNavBar
} from "@phx/design-system";
import {useHotkeys, useIsMobile} from "@phx/design-system/hooks";
import {useCallback as useCallback3, useEffect, useState as useState2} from "react";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";

// src/hooks/use-pwa-context.ts
import {useContext} from "react";

// src/components/PwaProvider.tsx
import {
createContext,
useCallback,
useLayoutEffect,
useMemo,
useRef,
useState
} from "react";

// src/util/os.ts
var getCurrentOs = () => {
  const userAgent = window?.navigator?.userAgent ?? "";
  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return "ios";
  }
  if (/Android/.test(userAgent)) {
    return "android";
  }
  return "desktop";
};

// src/components/PwaProvider.tsx
import {
jsx
} from "react/jsx-runtime";
var PwaContext = createContext({
  pwaId: "",
  canInstall: false,
  isInstalled: false,
  isStandalone: false,
  installApp: () => {
  },
  addContact: () => {
  }
});
var InnerPwaContext = createContext({
  setShowAddToContacts: () => {
  },
  setShowIosInstallOverlay: () => {
  },
  showAddToContacts: false,
  showIosInstallOverlay: false
});
var isStandalone = navigator?.standalone ?? window.matchMedia("(display-mode: standalone)").matches;
var userOs = getCurrentOs();
var PwaProvider = ({ children, pwaId }) => {
  const [canInstall, setCanInstall] = useState(userOs === "ios");
  const [isInstalled, setIsInstalled] = useState(isStandalone);
  const [showIosInstallOverlay, setShowIosInstallOverlay] = useState(false);
  const installPrompt = useRef(null);
  const [showAddToContacts, setShowAddToContacts] = useState(false);
  const addContact = useCallback(() => {
    setShowAddToContacts(true);
  }, [setShowAddToContacts]);
  const installApp = useCallback(() => {
    if (userOs === "ios") {
      setShowIosInstallOverlay(true);
      return;
    }
    if (!installPrompt.current) {
      return;
    }
    installPrompt.current.prompt();
  }, []);
  const beforeInstallListener = useCallback((event) => {
    event.preventDefault();
    installPrompt.current = event.detail;
    setCanInstall(true);
  }, []);
  const appInstalledListener = useCallback(() => setIsInstalled(true), []);
  useLayoutEffect(() => {
    window.addEventListener("appinstalled", appInstalledListener);
    window.addEventListener("appinstallprompt", beforeInstallListener);
    return () => {
      window.removeEventListener("appinstalled", appInstalledListener);
      window.removeEventListener("appinstallprompt", beforeInstallListener);
    };
  }, [beforeInstallListener, appInstalledListener]);
  const values = useMemo(() => ({
    pwaId,
    canInstall: canInstall && !isStandalone,
    isInstalled,
    isStandalone,
    installApp,
    addContact
  }), [pwaId, canInstall, isInstalled, isStandalone, installApp, addContact]);
  const innerCtx = useMemo(() => ({
    setShowAddToContacts,
    setShowIosInstallOverlay,
    showAddToContacts,
    showIosInstallOverlay
  }), [
    setShowAddToContacts,
    setShowIosInstallOverlay,
    showAddToContacts,
    showIosInstallOverlay
  ]);
  return /* @__PURE__ */ jsx(PwaContext.Provider, {
    value: values,
    children: /* @__PURE__ */ jsx(InnerPwaContext.Provider, {
      value: innerCtx,
      children
    })
  });
};

// src/hooks/use-pwa-context.ts
var usePwaContext = () => {
  const ctx = useContext(PwaContext);
  return ctx;
};

// src/hooks/use-pwa-flags.ts
import {useRef as useRef2, useSyncExternalStore} from "react";

// src/util/flags.ts
var pwaFlags = ["addToHome", "addToContacts"];
var getFlagName = (pwaId, flag) => {
  switch (flag) {
    case "addToContacts":
      return `${pwaId}:hide-add-contact`;
    case "addToHome":
      return `${pwaId}:hide-quick-access`;
    default:
      return "";
  }
};

// src/hooks/use-pwa-flags.ts
var usePwaFlags = (pwaId) => {
  const latestValue = useRef2();
  const localStore = {
    getSnapshot: () => {
      try {
        const pwaFlagSet = pwaFlags.reduce((acc, flag) => {
          acc[flag] = Boolean(localStorage.getItem(getFlagName(pwaId, flag)));
          return acc;
        }, {});
        const isDataEqual = JSON.stringify(pwaFlagSet) === JSON.stringify(latestValue.current);
        if (isDataEqual) {
          return latestValue.current;
        }
        latestValue.current = pwaFlagSet;
        return pwaFlagSet;
      } catch (error) {
        console.error(error);
        return pwaFlags.reduce((acc, flag) => {
          acc[flag] = false;
          return acc;
        }, {});
      }
    },
    subscribe: (listener) => {
      window.addEventListener("storage", listener);
      return () => void window.removeEventListener("storage", listener);
    }
  };
  const flags2 = useSyncExternalStore(localStore.subscribe, localStore.getSnapshot);
  const setFlag = (flag, value) => {
    try {
      const flagName = getFlagName(pwaId, flag);
      localStorage.setItem(flagName, String(value));
      window.dispatchEvent(new StorageEvent("storage", {
        key: flagName,
        newValue: String(value)
      }));
    } catch (error) {
      console.error(error);
    }
  };
  return { flags: flags2, setFlag };
};

// src/hooks/use-save-contact.ts
import {useCallback as useCallback2} from "react";
var userOs2 = getCurrentOs();
var DOWNLOAD_NAME = "myPrescryptive.vcf";
var saveIOSContact = (contactCardUrl) => {
  const headers = new Headers;
  headers.append("Content-type", "text/vcard; charset=utf-8");
  headers.append("Content-Disposition", `attachment; filename="${DOWNLOAD_NAME}";`);
  fetch(contactCardUrl, { headers }).then((response) => response.blob()).then((blob) => {
    const blobUrl = window.URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.href = blobUrl;
    anchor.download = DOWNLOAD_NAME;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    window.URL.revokeObjectURL(blobUrl);
  }).catch(() => {
  }).finally(() => {
  });
};
var saveContact = (contactCardUrl) => {
  const anchor = document.createElement("a");
  anchor.download = DOWNLOAD_NAME;
  anchor.href = contactCardUrl;
  anchor.type = "text/vcard";
  anchor.target = "_blank";
  anchor.click();
};
var useSaveContact = () => {
  const contactCardUrl = "/static/libraries/pwa/vcf/my-prescryptive.vcf";
  const iOSHandler = useCallback2(() => saveIOSContact(contactCardUrl), [contactCardUrl]);
  const defaultHandler = useCallback2(() => saveContact(contactCardUrl), [contactCardUrl]);
  if (userOs2 === "ios") {
    return iOSHandler;
  }
  return defaultHandler;
};

// src/components/AddContactOverlay.tsx
import {
jsx as jsx2,
jsxs
} from "react/jsx-runtime";
var AddContactOverlay = ({
  dismiss,
  visible
}) => {
  const { setBackOverrideState } = useNavBar();
  const location = useLocation();
  const { pwaId } = usePwaContext();
  const saveContact2 = useSaveContact();
  const isMobile = useIsMobile();
  const [showModal, setShowModal] = useState2(false);
  const { t } = useTranslation("pwa");
  const { flags: flags2, setFlag } = usePwaFlags(pwaId);
  const closeModal = useCallback3(() => {
    setFlag("addToContacts", true);
    setShowModal(false);
    dismiss();
  }, [setShowModal, dismiss]);
  const closeTip = useCallback3(() => {
    if (!visible) {
      return;
    }
    if (flags2.addToContacts) {
      dismiss();
    } else {
      setShowModal(true);
    }
  }, [dismiss, setShowModal, visible]);
  useHotkeys([["Escape", closeTip]]);
  useEffect(() => {
    if (!visible) {
      return;
    }
    setBackOverrideState({
      overrideFn: closeTip
    });
    return () => setBackOverrideState({ overrideFn: null });
  }, [closeTip, visible]);
  useEffect(() => closeTip(), [location.key]);
  return /* @__PURE__ */ jsxs(Cover, {
    visible,
    children: [
      /* @__PURE__ */ jsxs(Stack, {
        align: "center",
        gap: "lg",
        p: "sm",
        w: "100%",
        children: [
          /* @__PURE__ */ jsx2(Box, {
            children: /* @__PURE__ */ jsx2("img", {
              src: "/static/libraries/pwa/illustrations/add-to-contacts.svg",
              alt: t("addContact.illustrations.addContact"),
              width: "200",
              height: "200"
            })
          }),
          /* @__PURE__ */ jsxs(Stack, {
            gap: "sm",
            children: [
              /* @__PURE__ */ jsx2(Heading1, {
                ta: "center",
                children: t("addContact.heading")
              }),
              /* @__PURE__ */ jsx2(Body1, {
                ta: "center",
                children: t("addContact.instructions", {
                  appName: "myPrescryptive"
                })
              })
            ]
          }),
          /* @__PURE__ */ jsx2(Button, {
            fullWidth: isMobile,
            variant: "contained",
            onClick: saveContact2,
            "data-testid": "save-tip-button",
            children: t("addContact.saveButton")
          }),
          /* @__PURE__ */ jsxs(Flex, {
            gap: "xxxs",
            children: [
              /* @__PURE__ */ jsx2(Body1, {
                inline: true,
                children: t("addContact.alreadyDone")
              }),
              /* @__PURE__ */ jsx2(TextButton, {
                onClick: closeTip,
                "data-testid": "dismiss-tip-button",
                children: t("addContact.dismiss")
              })
            ]
          })
        ]
      }),
      /* @__PURE__ */ jsx2(Modal, {
        opened: showModal,
        onClose: closeModal,
        title: t("addContact.modal.title"),
        children: /* @__PURE__ */ jsxs(Stack, {
          gap: "lg",
          children: [
            /* @__PURE__ */ jsx2(Body1, {
              children: t("addContact.modal.content", {
                appName: "myPrescryptive"
              })
            }),
            /* @__PURE__ */ jsx2(Flex, {
              align: "center",
              justify: "center",
              children: /* @__PURE__ */ jsx2("img", {
                src: "/static/libraries/pwa/illustrations/add-to-contacts-from-menu.svg",
                alt: t("addContact.illustrations.addFromMenu"),
                width: "340",
                height: "180"
              })
            }),
            /* @__PURE__ */ jsx2(Button, {
              onClick: closeModal,
              children: t("addContact.modal.done")
            })
          ]
        })
      })
    ]
  });
};

// src/components/IOSAddToHomeOverlay.tsx
import {
Body1 as Body12,
Box as Box2,
Button as Button2,
Cover as Cover2,
Flex as Flex2,
Heading1 as Heading12,
IconIosShare,
Modal as Modal2,
Stack as Stack2,
TextButton as TextButton2,
useNavBar as useNavBar2
} from "@phx/design-system";
import {useHotkeys as useHotkeys2} from "@phx/design-system/hooks";
import {useCallback as useCallback4, useEffect as useEffect2, useState as useState3} from "react";
import {Trans, useTranslation as useTranslation2} from "react-i18next";
import {useLocation as useLocation2} from "react-router-dom";
import {
jsx as jsx3,
jsxs as jsxs2
} from "react/jsx-runtime";
var IOSAddToHomeOverlay = ({
  dismiss,
  visible
}) => {
  const { setBackOverrideState } = useNavBar2();
  const location = useLocation2();
  const { pwaId } = usePwaContext();
  const [showModal, setShowModal] = useState3(false);
  const { t } = useTranslation2("pwa");
  const { flags: flags2, setFlag } = usePwaFlags(pwaId);
  const closeModal = useCallback4(() => {
    setFlag("addToHome", true);
    setShowModal(false);
    dismiss();
  }, [setShowModal, dismiss]);
  const closeTip = useCallback4(() => {
    if (!visible) {
      return;
    }
    if (flags2.addToHome) {
      dismiss();
    } else {
      setShowModal(true);
    }
  }, [dismiss, setShowModal, visible]);
  useHotkeys2([["Escape", closeTip]]);
  useEffect2(() => {
    if (!visible) {
      return;
    }
    setBackOverrideState({
      overrideFn: closeTip
    });
    return () => setBackOverrideState({ overrideFn: null });
  }, [closeTip, visible]);
  useEffect2(() => closeTip(), [location.key]);
  return /* @__PURE__ */ jsxs2(Cover2, {
    visible,
    children: [
      /* @__PURE__ */ jsxs2(Stack2, {
        align: "center",
        gap: "lg",
        p: "sm",
        w: "100%",
        children: [
          /* @__PURE__ */ jsx3(Box2, {
            children: /* @__PURE__ */ jsx3("img", {
              src: "/static/libraries/pwa/illustrations/add-to-home.svg",
              alt: t("ios.addToHome.illustrations.addToHome"),
              width: "200",
              height: "200"
            })
          }),
          /* @__PURE__ */ jsxs2(Stack2, {
            gap: "sm",
            children: [
              /* @__PURE__ */ jsx3(Heading12, {
                ta: "center",
                children: t("ios.addToHome.heading", {
                  appName: "myPrescryptive"
                })
              }),
              /* @__PURE__ */ jsx3(Body12, {
                ta: "center",
                children: /* @__PURE__ */ jsx3(Trans, {
                  i18nKey: "ios.addToHome.instructions",
                  defaults: 'Add an icon to your home screen by tapping on the share button <icon /> in Safari\'s toolbar, scroll down, and select "Add to Home Screen".',
                  components: { icon: /* @__PURE__ */ jsx3(IconIosShare, {}) }
                })
              })
            ]
          }),
          /* @__PURE__ */ jsxs2(Flex2, {
            gap: "xxxs",
            children: [
              /* @__PURE__ */ jsx3(Body12, {
                inline: true,
                children: t("ios.addToHome.alreadyDone")
              }),
              /* @__PURE__ */ jsx3(TextButton2, {
                onClick: closeTip,
                "data-testid": "dismiss-tip-button",
                children: t("ios.addToHome.dismiss")
              })
            ]
          })
        ]
      }),
      /* @__PURE__ */ jsx3(Modal2, {
        opened: showModal,
        onClose: closeModal,
        title: t("ios.addToHome.modal.title"),
        children: /* @__PURE__ */ jsxs2(Stack2, {
          gap: "xl",
          children: [
            /* @__PURE__ */ jsx3(Body12, {
              children: t("ios.addToHome.modal.content", {
                appName: "myPrescryptive"
              })
            }),
            /* @__PURE__ */ jsx3(Flex2, {
              align: "center",
              justify: "center",
              children: /* @__PURE__ */ jsx3("img", {
                src: "/static/libraries/pwa/illustrations/add-to-home-from-menu.svg",
                alt: t("ios.addToHome.illustrations.addFromMenu"),
                width: "340",
                height: "180"
              })
            }),
            /* @__PURE__ */ jsx3(Button2, {
              onClick: closeModal,
              children: t("ios.addToHome.modal.done")
            })
          ]
        })
      })
    ]
  });
};

// src/components/PwaOverlays.tsx
import {
jsx as jsx4,
jsxs as jsxs3,
Fragment
} from "react/jsx-runtime";
var PwaOverlays = () => {
  const {
    setShowAddToContacts,
    setShowIosInstallOverlay,
    showAddToContacts,
    showIosInstallOverlay
  } = useContext2(InnerPwaContext);
  return /* @__PURE__ */ jsxs3(Fragment, {
    children: [
      /* @__PURE__ */ jsx4(IOSAddToHomeOverlay, {
        visible: showIosInstallOverlay,
        dismiss: () => setShowIosInstallOverlay(false)
      }),
      /* @__PURE__ */ jsx4(AddContactOverlay, {
        visible: showAddToContacts,
        dismiss: () => setShowAddToContacts(false)
      })
    ]
  });
};
export {
  usePwaFlags,
  usePwaContext,
  getCurrentOs,
  PwaProvider,
  PwaOverlays
};

//# debugId=826C3A3C30B02B1164756E2164756E21
