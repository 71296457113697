import {
    type AddressProps,
    Body1,
    ElevatedCard,
    Stack,
} from '@phx/design-system';
import { geolocationDistance, useGeolocation } from '@phx/location-utils';
import { PharmacyInfo } from '@phx/myphx-lib';
import { t } from 'i18next';
import { useMemo } from 'react';

import type {
    MailOrderProviderInfoFragment,
    PharmacyProviderInfoFragment,
} from '../../../graphql/generated/graphql';
import { usePatientContext } from '../../../hooks/use-patient-context';
import { NoProviderOffers } from '../../provider-offers/cards/NoProviderOffers';

type LocationCardListProps = {
    providers: Array<
        MailOrderProviderInfoFragment | PharmacyProviderInfoFragment
    >;
    onSelect: (id: string) => void;
};

export const LocationCardList = ({
    providers,
    onSelect,
}: LocationCardListProps) => {
    const { geolocation } = useGeolocation();
    const { preferences } = usePatientContext();
    const { favoritePharmacies } = preferences;
    const { checkIsFavorite, toggleFavorite } = favoritePharmacies;

    const pharmacyDetails = useMemo(() => {
        const results = providers.map((provider) => {
            const props = {
                __typename: provider.__typename,
                id: provider.id,
                name: provider.name,
                favorite: checkIsFavorite(provider.id),
                toggleFavorite: () => toggleFavorite(provider.id),
                distance: undefined,
            };
            switch (provider.__typename) {
                case 'MailOrderProvider':
                    return {
                        ...props,
                        url: provider?.url ?? undefined,
                        phone: provider.phoneNumber,
                    };
                case 'PharmacyProvider': {
                    const distance =
                        geolocation &&
                        geolocationDistance(geolocation, {
                            latitude: provider.latitude,
                            longitude: provider.longitude,
                        });
                    return {
                        ...props,
                        address: provider?.address as AddressProps['address'],
                        distance: distance
                            ? {
                                  distance: distance,
                                  units: t('common.distance.distanceUnit'),
                              }
                            : undefined,
                    };
                }
            }
            return props;
        });

        results.sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        });
        results.sort(
            (a, b) =>
                (a?.distance?.distance ?? 999) - (b?.distance?.distance ?? 999)
        );

        return results;
    }, [providers]);

    return (
        <Stack gap="lg">
            <Body1>
                {t('providerOffers.pharmacies', { count: providers.length })}
            </Body1>
            <Stack gap="sm" role="list">
                {pharmacyDetails.length ? (
                    pharmacyDetails.map((pharmacy) => {
                        return (
                            <ElevatedCard
                                key={pharmacy.id}
                                onClick={() => onSelect(pharmacy.id)}
                                role="listitem"
                            >
                                <PharmacyInfo {...pharmacy} />
                            </ElevatedCard>
                        );
                    })
                ) : (
                    <NoProviderOffers />
                )}
            </Stack>
        </Stack>
    );
};
