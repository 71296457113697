export const AI_CLOUD_ROLE = 'ux-my-prescryptive';
export const defaultConfig = {
    enableAutoRouteTracking: true,
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    enableUnhandledPromiseRejectionTracking: true,
    /** do not add correlation headers to the following domains. */
    correlationHeaderExcludedDomains: ['launchdarkly.com'],
};
