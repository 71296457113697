import {
    Body2,
    Box,
    Flex,
    HorizontalDivider,
    IconChevronRight,
    OutlinedCard,
    Stack,
    Subtitle1,
    Subtitle2,
    TextButton,
} from '@phx/design-system';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ConditionalComponent } from '../../../components/utility/ConditionalComponent';
import { getFragment } from '../../../graphql';
import { getLocationInfoFragment } from '../../../graphql/client/helpers/get-location-fragment';
import type {
    DigitallyReceivedPrescriberOrderInfoFragment,
    PriceQuoteInfoFragment,
} from '../../../graphql/generated/graphql';
import {
    formatToLocalizedDate,
    getAbsoluteRoute,
    titleCase,
} from '../../../util';

export type LastOrderActivityProps = {
    priceQuote?: PriceQuoteInfoFragment;
    prescriberOrder: DigitallyReceivedPrescriberOrderInfoFragment;
};

export const LastOrderActivity = ({
    priceQuote,
    prescriberOrder,
}: LastOrderActivityProps) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const pharmacy = priceQuote
        ? getLocationInfoFragment(priceQuote?.location)
        : undefined;
    const pharmacyName = getFragment(pharmacy)?.name;

    const hasOrderDate = !!prescriberOrder.latestSelectedPriceQuoteTimestamp;

    return (
        <ConditionalComponent
            condition={!!prescriberOrder.latestSelectedPriceQuote}
        >
            <OutlinedCard>
                <Stack gap="xs">
                    <Subtitle1>{t('orderDetails.lastActivity')}</Subtitle1>
                    <HorizontalDivider />
                    <Stack gap="xs">
                        <Flex gap="xs" justify="space-between">
                            <Stack gap="xxxs">
                                <Subtitle2>
                                    {t('orderDetails.sentToPharmacy')}
                                </Subtitle2>
                                {pharmacyName ? (
                                    <Body2>{titleCase(pharmacyName)}</Body2>
                                ) : null}
                            </Stack>
                            {hasOrderDate ? (
                                <Body2>
                                    {formatToLocalizedDate(
                                        new Date(
                                            prescriberOrder.latestSelectedPriceQuoteTimestamp!
                                        ),
                                        { dateStyle: 'medium' },
                                        i18n.language
                                    )}
                                </Body2>
                            ) : null}
                        </Flex>
                        <Box>
                            <TextButton
                                RightIcon={IconChevronRight}
                                onClick={() =>
                                    navigate(
                                        getAbsoluteRoute(
                                            'orders.prescriberOrderId.confirmation',
                                            {
                                                prescriberOrderId:
                                                    prescriberOrder.id,
                                            }
                                        )
                                    )
                                }
                            >
                                {t('orderDetails.viewOrder')}
                            </TextButton>
                        </Box>
                    </Stack>
                </Stack>
            </OutlinedCard>
        </ConditionalComponent>
    );
};
