import type { ResultOf } from '@graphql-typed-document-node/core';

import { FragmentMap } from '../../generated/graphql';

type FragmentRef<TKey extends string> = { __typename?: unknown } & {
    [' $fragmentRefs']?: { [key in TKey]?: unknown } | undefined;
};

type FragmentNames = keyof typeof FragmentMap;

type Fragment<FragmentName extends FragmentNames> = ResultOf<
    (typeof FragmentMap)[FragmentName]
>;

// return non-nullable if `fragment` type is non-nullable
export function getFragment<FragmentName extends FragmentNames>(
    fragment: FragmentRef<FragmentName>
): Fragment<FragmentName>;
// return nullable if `fragment` type is nullable
export function getFragment<FragmentName extends FragmentNames>(
    fragment: FragmentRef<FragmentName> | undefined | null
): Fragment<FragmentName> | undefined;

/**
 * @param fragment A reference generated by a fragment included in a query
 * @returns A fragment with resolved fragment properties
 */
export function getFragment<FragmentName extends FragmentNames>(
    fragment: FragmentRef<FragmentName> | undefined | null
): Fragment<FragmentName> | undefined {
    if (fragment === undefined || fragment === null) {
        return undefined;
    }
    return fragment as Fragment<FragmentName>;
}

// return non-nullable if `fragment` type is non-nullable
export function getFragments<FragmentName extends FragmentNames>(
    fragments: FragmentRef<FragmentName>[]
): Fragment<FragmentName>[];
// return nullable if `fragment` type is nullable
export function getFragments<FragmentName extends FragmentNames>(
    fragments: FragmentRef<FragmentName>[] | undefined | null
): Fragment<FragmentName>[] | undefined;

/**
 * @param fragments A list of references generated by a fragment included in a query
 * @returns A list of fragments with resolved fragment properties
 */
export function getFragments<FragmentName extends FragmentNames>(
    fragments: FragmentRef<FragmentName>[] | undefined | null
): Fragment<FragmentName>[] | undefined {
    return fragments?.map((fragment) => getFragment(fragment));
}
