import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { PatientProductDetails } from '../../../components/preview-details/components/PatientProductDetails';
import { OfferSelection } from '../../../components/provider-offers';
import { getFragment } from '../../../graphql';
import { GetProviderOffersDocument } from '../../../graphql/generated/graphql';
import { QueryLoader } from '../../../loaders';
import { getAbsoluteRoute } from '../../../util';

export const PrescriptionOfferSelectionRoute = () => {
    const { providerOffersId } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const Loader = QueryLoader<typeof GetProviderOffersDocument>;

    if (!providerOffersId) {
        return <Navigate to={getAbsoluteRoute('error')} />;
    }

    return (
        <Loader
            query={GetProviderOffersDocument}
            variables={{
                id: providerOffersId,
            }}
            render={(data) => {
                const providerOffers = getFragment(data.providerOffers);
                const offer = getFragment(providerOffers?.offers[0]);
                const patient = getFragment(offer?.patient);
                const product = getFragment(offer?.product);

                const header = offer ? (
                    <PatientProductDetails
                        patientName={patient?.fullName}
                        productDescription={product?.description}
                        quantity={offer.quantity}
                    />
                ) : null;

                if (providerOffers) {
                    const onSelect = (selectedOfferId?: string) =>
                        navigate(
                            getAbsoluteRoute(
                                'cabinet.rxId.providerOffersId.offer.root',
                                {
                                    prescriptionId: providerOffers.product.id,
                                    providerOffersId: providerOffers.id,
                                    offerId: selectedOfferId ?? '',
                                }
                            )
                        );

                    const buttonText = t(
                        'offerSelection.button.previewDetails'
                    );

                    return providerOffers.offers.length > 1 ? (
                        <OfferSelection
                            providerOffers={providerOffers}
                            header={header}
                            onSelect={onSelect}
                            buttonText={buttonText}
                        />
                    ) : (
                        <div>Render Overview</div>
                    );
                }

                return <Navigate to={getAbsoluteRoute('error')} />;
            }}
        />
    );
};
