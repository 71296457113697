import {
    DividedText,
    Flex,
    IconEdit,
    LoadingPlaceholder,
    Stack,
    Subtitle1,
    TextButton,
} from '@phx/design-system';
import { useTelemetryContext } from '@phx/instrumentation/client';
import type { PropsWithChildren } from 'react';
import type React from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { getFragment, getFragments } from '../../graphql';
import {
    DrugFamilyDocument,
    type DrugFamilyInfoFragment,
} from '../../graphql/generated/graphql';
import { QueryLoader } from '../../loaders';
import { getTotalQuantityDescription } from '../../util';

type DrugFamilyHeaderProps = {
    topNode?: React.ReactNode;
    drugId: string;
    editClicked?: () => void;
};

export const DrugFamilyHeader = ({
    topNode,
    drugId,
    editClicked,
    children,
}: PropsWithChildren<DrugFamilyHeaderProps>) => {
    const Loader = QueryLoader<typeof DrugFamilyDocument>;
    return (
        <Loader
            query={DrugFamilyDocument}
            variables={{ id: drugId }}
            loadingPlaceholderComponent={<LoadingPlaceholder rows={2} />}
            render={(data) => {
                if (data?.drugFamily) {
                    const drugFamily = getFragment(data.drugFamily);

                    return (
                        <DrugFamilyHeaderView
                            drugFamily={drugFamily}
                            editClicked={editClicked}
                            topNode={topNode}
                        >
                            {children}
                        </DrugFamilyHeaderView>
                    );
                }

                return children ? <>{children}</> : null;
            }}
        />
    );
};

type DrugFamilyHeaderviewProps = {
    topNode?: React.ReactNode;
    drugFamily: DrugFamilyInfoFragment;
    editClicked?: () => void;
};

export const DrugFamilyHeaderView = ({
    topNode,
    drugFamily,
    editClicked,
    children,
}: PropsWithChildren<DrugFamilyHeaderviewProps>) => {
    const { t } = useTranslation();
    const { telemetryInstance } = useTelemetryContext();
    const [searchParams] = useSearchParams();

    const chosenQuantity =
        searchParams.get('qInput') ?? searchParams.get('quantity');
    const product = getFragment(drugFamily.defaultProduct);
    const quantity = chosenQuantity
        ? Number(chosenQuantity)
        : (product?.modeQuantity ?? 30);

    const form = getFragments(drugFamily.forms).find(
        (form) => form.defaultNdc === drugFamily.defaultNdc
    );

    const matchingPackage =
        form &&
        getFragments(form.packages).find(
            (packageInfo) => packageInfo.id === drugFamily.defaultNdc
        );

    const quantityDesc =
        matchingPackage &&
        getTotalQuantityDescription(quantity, form, matchingPackage);

    const handleEditClick = () => {
        //Log telemetry event
        telemetryInstance.logEvent({
            name: 'Drugconfig open edit drug',
            source: 'DrugFamilyHeader',
        });
        editClicked && editClicked();
    };

    return (
        <Stack gap="xs" data-testid="drug-family-header">
            {topNode && topNode}
            <Stack gap="xxs">
                <Subtitle1 data-testid="search-product-details-header">
                    {product.name}
                </Subtitle1>
                <Flex justify="space-between" align="center" gap="md">
                    <DividedText
                        entries={[
                            product.strengthDisplayText ?? '',
                            quantityDesc?.longText ?? '',
                        ]}
                    />
                    {editClicked && (
                        <TextButton
                            data-testid="edit-drug-configuration"
                            size="sm"
                            onClick={handleEditClick}
                            LeftIcon={IconEdit}
                        >
                            {t('drugConfig.Edit')}
                        </TextButton>
                    )}
                </Flex>
            </Stack>
            {children}
        </Stack>
    );
};
