import { ApplicationInsights, } from '@microsoft/applicationinsights-web';
import { AI_CLOUD_ROLE, defaultConfig } from '../config';
import { logging, } from '../core';
export const TelemetryProviderFactory = (() => {
    let instance;
    let appInsights;
    const createInstance = (instrumentationKey, buildNumber, extensions, extensionConfig) => {
        const snippet = {
            config: {
                connectionString: instrumentationKey,
                ...defaultConfig,
                extensions,
                extensionConfig,
            },
        };
        appInsights = new ApplicationInsights(snippet);
        /** If we continue to add filters / additional information to events we may want to break this out into different files */
        appInsights.addTelemetryInitializer((envelope) => {
            if (envelope?.baseData?.uri &&
                envelope?.baseData?.uri?.includes('login/callback')) {
                return false;
            }
            if (envelope.tags === undefined) {
                envelope.tags = [];
            }
            envelope.tags['ai.cloud.role'] = AI_CLOUD_ROLE;
            if (buildNumber) {
                if (envelope.data === undefined) {
                    envelope.data = {};
                }
                envelope.data['build_version'] = buildNumber;
            }
            return true;
        });
        appInsights.loadAppInsights();
        appInsights.trackPageView();
        if (buildNumber) {
            appInsights.context.application.build = buildNumber;
            appInsights.context.application.ver = buildNumber;
        }
        return {
            logEvent: (event) => {
                logging.logEvent(event, appInsights);
            },
            logError: (event) => {
                logging.logError(event, appInsights);
            },
            logUnknownError: (event, error) => {
                logging.logUnknownError(event, error, appInsights);
            },
            logMetric: (event) => {
                logging.logMetric(event, appInsights);
            },
            getSessionDetails: () => ({
                sessionId: appInsights.context.getSessionId(),
                traceId: appInsights.context.telemetryTrace.traceID,
            }),
            setUserId: (userId) => {
                appInsights.setAuthenticatedUserContext(userId);
            },
        };
    };
    return {
        getInstance: ({ instrumentationKey, buildNumber, extensions, extensionConfig, }) => {
            if (!instance) {
                /** TODO: This is a temporary hack for storybook we will need a better solution to disable.
                 * note: there seems to be a bug in sb's vite builder where env var string include "" around the value
                 */
                if (!instrumentationKey ||
                    instrumentationKey.includes('explorer')) {
                    instance = {};
                }
                else {
                    instance = createInstance(instrumentationKey, buildNumber, extensions, extensionConfig);
                }
            }
            return instance;
        },
    };
})();
