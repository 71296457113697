import {
    Autocomplete,
    Body1,
    Button,
    Heading1,
    Heading2,
    Stack,
} from '@phx/design-system';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getFragment } from '../../graphql';
import type {
    CashOfferInfoFragment,
    ChainPharmacyInfoFragment,
    InsuranceOfferInfoFragment,
    MailOrderProviderInfoFragment,
    NonPriceableOfferInfoFragment,
    PharmacyInfoFragment,
    PharmacyProviderInfoFragment,
} from '../../graphql/generated/graphql';
import { isPriceableOffer } from '../../util/offers/is-priceable-offer';
import { DrugFamilyHeader } from '../common/DrugFamilyHeader';
import { NoPricing, Pricing } from '../common/pricing';

import { ProviderTransferTimeline } from './components';

export interface VerifyPrescriptionTransferProps {
    from:
        | ChainPharmacyInfoFragment
        | PharmacyInfoFragment
        | PharmacyProviderInfoFragment
        | MailOrderProviderInfoFragment
        | undefined;
    to:
        | ChainPharmacyInfoFragment
        | PharmacyInfoFragment
        | PharmacyProviderInfoFragment
        | MailOrderProviderInfoFragment
        | undefined;
    offer?:
        | CashOfferInfoFragment
        | InsuranceOfferInfoFragment
        | NonPriceableOfferInfoFragment
        | null;
    transferLoading?: boolean;
    onTransferPrescription: (prescriptionId: string | undefined) => void;
}

export const VerifyPrescriptionTransfer = ({
    from,
    to,
    offer,
    transferLoading,
    onTransferPrescription,
}: VerifyPrescriptionTransferProps) => {
    const { t } = useTranslation();
    const [prescriptionId, setPrescriptionId] = useState<string | undefined>(
        undefined
    );

    const product = getFragment(offer?.product);
    const priceSection = isPriceableOffer(offer) ? (
        <Pricing offer={offer} />
    ) : (
        <NoPricing />
    );
    return (
        <Stack>
            <Stack>
                <Heading1>{t('verify.transfer.title')}</Heading1>
                <Body1>{t('verify.transfer.subtitle')}</Body1>
                <ProviderTransferTimeline to={to} from={from} />
                <Body1>{t('verify.transfer.description')}</Body1>
            </Stack>
            <Stack>
                <Heading2>{t('verify.transfer.subheader')}</Heading2>
                {product && <DrugFamilyHeader drugId={product.id} />}
                <Autocomplete
                    label={t('verify.transfer.autocomplete.label')}
                    description={t('verify.transfer.autocomplete.description')}
                    onChange={setPrescriptionId}
                />
            </Stack>
            {priceSection}
            <Button
                loading={transferLoading}
                onClick={() => {
                    offer && onTransferPrescription(prescriptionId);
                }}
                size="lg"
            >
                {t('verify.transfer.buttonText')}
            </Button>
        </Stack>
    );
};
