import { AuthProvider, type AuthProviderProps } from '@phx/auth/client';
import { ThemeProvider } from '@phx/design-system/framework';
import {
    FeatureFlagProvider,
    makeFlagProvider,
} from '@phx/feature-flags/client';
import {
    TelemetryProvider,
    type TelemetryProviderProps,
} from '@phx/instrumentation/client';
import theme from '@phx/phx-theme';
import { PwaProvider } from '@phx/pwa';
import { TalkativeInitializer } from '@phx/talkative';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import '@phx/phx-theme/theme.css';
import './i18n';

import { ErrorBoundary } from './components/common/ErrorBoundary';
import { AppLoadedEventDispatcher } from './components/layout/AppLoadedEventDispatcher';
import { UserPrefsInitializer } from './components/user/UserPrefsInitializer';
import { DevTools } from './components/utility/DevTools';
import { RootSuspenseBoundary } from './components/utility/RootSuspenseBoundary';
import { AppConfigProvider } from './contexts/AppConfigProvider';
import { ErrorSessionProvider } from './contexts/ErrorSessionProvider';
import { GraphQLProvider } from './contexts/GraphQLProvider';
import router from './routes';

import { Box } from '@phx/design-system';

import RootErrorRoute from './routes/error/RootErrorRoute';

const authConfig: AuthProviderProps = {
    authUrl: import.meta.env.VITE_AUTH_URL,
    clientId: import.meta.env.VITE_AUTH_CLIENT_ID,
    redirectUri: import.meta.env.VITE_AUTH_REDIRECT_URI,
    rootUrl: '/home',
    tokenUrl: import.meta.env.VITE_AUTH_TOKEN_URL,
};

const telemetryConfig: TelemetryProviderProps = {
    instrumentationKey: import.meta.env
        .VITE_APPLICATION_INSIGHTS_CONNECTION_STRING,
};

(async () => {
    const provider = await makeFlagProvider(
        import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_ID
    );

    createRoot(document.getElementById('root')!).render(
        <React.StrictMode>
            <ThemeProvider theme={theme}>
                <ErrorSessionProvider>
                    <ErrorBoundary
                        fallback={
                            <Box p="md">
                                <RootErrorRoute />
                            </Box>
                        }
                    >
                        <TelemetryProvider {...telemetryConfig}>
                            <FeatureFlagProvider provider={provider}>
                                <AppConfigProvider>
                                    <RootSuspenseBoundary>
                                        <PwaProvider pwaId="phx">
                                            <AuthProvider {...authConfig}>
                                                <GraphQLProvider>
                                                    <RouterProvider
                                                        router={router}
                                                    />
                                                </GraphQLProvider>
                                            </AuthProvider>
                                            <AppLoadedEventDispatcher />
                                        </PwaProvider>
                                        <UserPrefsInitializer />
                                    </RootSuspenseBoundary>
                                    {import.meta.env.MODE ===
                                    'production' ? null : (
                                        <DevTools />
                                    )}
                                </AppConfigProvider>
                            </FeatureFlagProvider>
                            <TalkativeInitializer />
                        </TelemetryProvider>
                    </ErrorBoundary>
                </ErrorSessionProvider>
            </ThemeProvider>
        </React.StrictMode>
    );
})();
