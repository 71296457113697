import type { RouteObject } from 'react-router-dom';

import routes from '../../app-routes';

const patientRoutes: RouteObject[] = [{
    path: routes.cabinet.patientId.root,
    element: <div>/cabinet/:patientId</div>,
    children: [
        {
            path: routes.cabinet.patientId.digitalRx.root,
            element: <div>/cabinet/:patientId/digitalrx</div>,
            children: [
                {
                    path: routes.cabinet.patientId.digitalRx.rxId,
                    element: (
                        <div>/cabinet/:patientId/digitalrx/:prescriptionId</div>
                    ),
                },
            ],
        },
    ],
}];

export default patientRoutes;
