import type { FlagSet } from '../../../generated/feature-flags';
import { makePersistentVar } from '../../../util/make-persistent-var/make-persistent-var';

export type FlagType = {
    key: keyof FlagSet;
    value: FlagSet[keyof FlagSet];
};

const overriddenFlagsVar = makePersistentVar<FlagType[]>(
    [],
    'overriddenLDflags'
);

export const ClientFlagVariables = {
    overriddenFlagsVar,
};
