import { Heading1, HorizontalDivider, Stack } from '@phx/design-system';
import { useTranslation } from 'react-i18next';

import { ContactUsWidget } from '../../components/common/ContactUsWidget';

import { FaqSection } from './help-center-components/faq/FaqSection';
import { GettingStartedCard } from './help-center-components/GettingStartedCard';
import { HelpCenterSearchInput } from './help-center-components/search-input/HelpCenterSearchInput';
import { WhatsNewSection } from './help-center-components/whats-new/WhatsNewSection';

const HelpCenterRoute = () => {
    const { t } = useTranslation(['help-center-locales/help-center']);

    return (
        <Stack>
            <Stack gap="sm">
                <Heading1>{t('helpCenter.header')}</Heading1>
                <HelpCenterSearchInput />
            </Stack>

            <GettingStartedCard />
            <WhatsNewSection />
            <FaqSection />
            <HorizontalDivider variant="full" />
            <ContactUsWidget />
        </Stack>
    );
};

export default HelpCenterRoute;
