import {
    Body1,
    Flex,
    Group,
    HorizontalDivider,
    IconChevronRight,
    IconLocationOn,
    Modal,
    Stack,
    Subtitle2,
} from '@phx/design-system';
import { constants } from '@phx/design-system/framework';
import { geolocationDistance, useGeolocation } from '@phx/location-utils';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { getFragment } from '../../../graphql';
import type { PharmacyProviderInfoFragment } from '../../../graphql/generated/graphql';
import { getTodaysHours } from '../../../util/pharmacy/get-todays-hours';

type MoreProvidersDialogProps = {
    isOpen: boolean;
    close: () => void;
    onSelected: (id: string) => void;
    title: string;
    moreProviders: PharmacyProviderInfoFragment[];
};

export const MoreProvidersDialog = ({
    title,
    isOpen,
    close,
    onSelected,
    moreProviders,
}: MoreProvidersDialogProps) => {
    const { t } = useTranslation();
    const { geolocation } = useGeolocation();

    const distanceById = useMemo(() => {
        const results = new Map<string, number>();
        if (geolocation) {
            moreProviders.forEach((provider) => {
                results.set(
                    provider.id,
                    geolocationDistance(geolocation, {
                        latitude: provider.latitude,
                        longitude: provider.longitude,
                    })
                );
            });
        }

        return results;
    }, [geolocation, moreProviders]);

    return (
        <Modal opened={isOpen} onClose={close} title={title} size="xl">
            <Stack gap="md">
                <Body1>{t('offerPharmacyCard.selectLocation')}</Body1>
                <Stack p="xxxs" gap="md">
                    {moreProviders.map((provider, index) => {
                        const addressFragment = getFragment(provider.address);
                        const street = [
                            addressFragment.line1,
                            addressFragment.line2,
                        ].filter((x) => x);
                        const cityStateZip = `${addressFragment.city}, ${addressFragment.state} ${addressFragment.zipCode}`;

                        const distance = distanceById.get(provider.id) ?? '';
                        const formattedHours = getTodaysHours(provider);

                        return (
                            <Stack key={`loc-${provider.id}`} gap="lg">
                                <Flex
                                    direction="row"
                                    align="flex-start"
                                    gap="lg"
                                    data-testid={`pharmacy-price-card-show-more-option-${provider.id}-${index}`}
                                    onClick={() => onSelected(provider.id)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <Flex
                                        direction="row"
                                        gap="xs"
                                        align="flex-start"
                                        flex={'1 1 auto'}
                                        py="xxxs"
                                    >
                                        <IconLocationOn color="primary" />
                                        <Flex
                                            direction="column"
                                            align="stretch"
                                        >
                                            <Group wrap="nowrap" gap="xs">
                                                <Subtitle2>
                                                    {street}{' '}
                                                    {constants.unicode.BULLET}{' '}
                                                    {cityStateZip}
                                                </Subtitle2>
                                            </Group>
                                            <Flex direction="row" gap="xxs">
                                                {distance && (
                                                    <>
                                                        <Body1>
                                                            {t(
                                                                'common.distance.distanceWithUnit',
                                                                {
                                                                    miles: distance,
                                                                }
                                                            )}
                                                        </Body1>
                                                        {
                                                            constants.unicode
                                                                .BULLET
                                                        }
                                                    </>
                                                )}
                                                <Body1>{formattedHours}</Body1>
                                            </Flex>
                                        </Flex>
                                        <IconChevronRight
                                            color="primary"
                                            style={{ marginLeft: 'auto' }}
                                        />
                                    </Flex>
                                </Flex>
                                {index < moreProviders.length - 1 ? (
                                    <HorizontalDivider variant="full" />
                                ) : null}
                            </Stack>
                        );
                    })}
                </Stack>
            </Stack>
        </Modal>
    );
};
