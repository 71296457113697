import { Body1, Box, IconSearch, Stack, TextButton } from '@phx/design-system';
import { useTranslation } from 'react-i18next';

import { getFragment } from '../../graphql';
import type { DrugFamilyInfoFragment } from '../../graphql/generated/graphql';
import { useDrugConfigSearchParams } from '../../hooks/use-drug-config-search-params';
import { removeGenericSuffix } from '../../util';
import { HighlightText } from '../common/HighlightText';

import { NoSearchResults } from './NoSearchResults';

type DrugSearchResultProps = {
    drug: DrugFamilyInfoFragment;
    searchInput: string;
    onDrugSelect: (drugId: string, searchParams?: string) => void;
};

const DrugSearchResult = ({
    drug,
    searchInput,
    onDrugSelect,
}: DrugSearchResultProps) => {
    const productInfo = getFragment(drug.defaultProduct);
    const searchParams = useDrugConfigSearchParams(drug);
    const drugName = removeGenericSuffix(drug.name);

    return (
        <TextButton
            __vars={{ '--button-justify': 'left' }}
            justify="left"
            data-testid="drug-menu-item-button"
            onClick={() => onDrugSelect(productInfo.id, searchParams)}
            LeftIcon={IconSearch}
            fullWidth
        >
            <HighlightText text={drugName} substring={searchInput} />
        </TextButton>
    );
};

type DrugSearchResultsProps = {
    results: DrugFamilyInfoFragment[];
    searchInput: string;
    onDrugSelect: (drugId: string, searchParams?: string) => void;
};

export const DrugSearchResults = ({
    results,
    searchInput,
    onDrugSelect,
}: DrugSearchResultsProps) => {
    const { t } = useTranslation();
    if (!results.length) {
        return (
            <Box>
                <NoSearchResults message={t('drugSearch.noResults')} />
            </Box>
        );
    }
    return (
        <Stack gap="md" align="stretch" justify="flex-start">
            <Body1>
                {t('drugSearch.searchResults', { length: results.length })}
            </Body1>
            <Stack gap="lg">
                {results.map((result) => (
                    <DrugSearchResult
                        key={result.id}
                        drug={result}
                        onDrugSelect={onDrugSelect}
                        searchInput={searchInput}
                    />
                ))}
            </Stack>
        </Stack>
    );
};
