import {
    Body1,
    Body2,
    HorizontalDivider,
    Stack,
    Subtitle1,
    TextInput,
} from '@phx/design-system';
import {
    geolocationDistance,
    getDistanceDifference,
} from '@phx/location-utils';
import type { Geolocation } from '@phx/location-utils';
import {
    IllustrationCard,
    NoResultsIllustration,
    PharmacyInfo,
    type PharmacyInfoProps,
} from '@phx/myphx-lib';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getFragment } from '../../graphql';
import type { SearchLocationsQuery } from '../../graphql/generated/graphql';
import { usePatientContext } from '../../hooks/use-patient-context';
import { convertLocationToPharmacyInfo } from '../../util/pharmacy/convert-location-info';
import { PhxLocationPicker } from '../cabinet/prescription/PhxLocationPicker';

export const InNetworkPharmacies = ({
    locations,
    geolocation,
}: {
    locations: SearchLocationsQuery['locations']['edges'];
    geolocation: Geolocation;
}) => {
    const { preferences } = usePatientContext();
    const { t } = useTranslation();

    const { favoritePharmacies } = preferences;
    const { checkIsFavorite, toggleFavorite } = favoritePharmacies;
    const [searchQuery, setSearchQuery] = useState('');
    const mappedLocations = locations
        .map((x) => getFragment(x.node))
        .filter(
            (location) =>
                !searchQuery ||
                caseMatch(getFragment(location).name, searchQuery)
        )
        .sort((a, b) =>
            getDistanceDifference(getFragment(a), getFragment(b), geolocation)
        );

    // @TODO: create function to group pharmacies by chain and display as group.
    return (
        <Stack gap="lg">
            <Stack gap="md">
                <PhxLocationPicker />
                <TextInput
                    label={t('pharmacy.inNetwork.inputPlaceholder')}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    value={searchQuery}
                />
            </Stack>
            <Stack gap="sm">
                {mappedLocations.length ? (
                    <Body2>
                        {t('pharmacy.inNetwork.countLabel', {
                            count: mappedLocations.length,
                        })}
                    </Body2>
                ) : (
                    <IllustrationCard
                        illustrationPosition="top"
                        Illustration={NoResultsIllustration}
                        illustrationSize="lg"
                        variant="bare"
                        withShadow={false}
                    >
                        <Stack gap="xxs">
                            <Subtitle1 ta="center">
                                {t('pharmacy.inNetwork.noResults.header')}
                            </Subtitle1>
                            <Body1 ta="center">
                                {t('pharmacy.inNetwork.noResults.body')}
                            </Body1>
                        </Stack>
                    </IllustrationCard>
                )}
                {mappedLocations?.map((locationInfoFragment, idx) => {
                    const pharmacyInfo =
                        convertLocationToPharmacyInfo(locationInfoFragment);
                    const location = getFragment(locationInfoFragment);
                    const distanceAmount = geolocationDistance(geolocation, {
                        latitude: location.latitude,
                        longitude: location.longitude,
                    });
                    const distance: PharmacyInfoProps['distance'] = {
                        distance: distanceAmount,
                        units: t('common.distance.distanceUnit'),
                    };

                    return (
                        <Fragment
                            key={`pharmacy-info-container-${locationInfoFragment.id}`}
                        >
                            {idx !== 0 ? <HorizontalDivider /> : null}
                            <PharmacyInfo
                                key={locationInfoFragment.id}
                                {...pharmacyInfo}
                                favorite={checkIsFavorite(
                                    locationInfoFragment.id
                                )}
                                toggleFavorite={() =>
                                    toggleFavorite(locationInfoFragment.id)
                                }
                                distance={distance}
                                day={t('pharmacy.hours.today')}
                            />
                        </Fragment>
                    );
                })}
            </Stack>
        </Stack>
    );
};

const caseMatch = (title: string, search: string) => {
    return title.toLowerCase().includes(search.toLowerCase());
};
