import { Body1, Stack, Subtitle2 } from '@phx/design-system';
import { useTranslation } from 'react-i18next';

import { RxForUser } from '../../cabinet/rx-for-user/RxForUser';
import { ConditionalComponent } from '../../utility/ConditionalComponent';

type PatientProductDetailsProps = {
    patientName?: string;
    productDescription?: string;
    quantity?: number | null;
};

export const PatientProductDetails = ({
    patientName,
    productDescription,
    quantity,
}: PatientProductDetailsProps) => {
    const { t } = useTranslation();

    return (
        <Stack gap="xxs">
            {patientName ? (
                <RxForUser patient={{ fullName: patientName }} />
            ) : null}
            <Subtitle2 data-testid="prescriber-order-product-details-header">{productDescription}</Subtitle2>
            <ConditionalComponent condition={!!quantity}>
                <Body1>
                    {t('order.prescribedQuantity', {
                        quantity,
                    })}
                </Body1>
            </ConditionalComponent>
        </Stack>
    );
};
