import { useConditionalBackOverride } from '@phx/design-system';
import { useTelemetryContext } from '@phx/instrumentation/client';
import { useState } from 'react';
import {
    Navigate,
    createSearchParams,
    useNavigate,
    useParams,
    useSearchParams,
} from 'react-router-dom';

import { ShoppingHeader } from '../../components/cabinet/shopping-header/ShoppingHeader';
import { DrugFamilyHeader } from '../../components/common/DrugFamilyHeader';
import { ProductConfiguration } from '../../components/product-configuration';
import { ProviderSelection } from '../../components/provider-offers';
import { getFragment } from '../../graphql';
import {
    GetProductOffersDocument,
    type GetProductOffersQuery,
} from '../../graphql/generated/graphql';
import { useNumericSearchParam } from '../../hooks/use-numeric-search-param';
import { usePatientContext } from '../../hooks/use-patient-context';
import { type DataHandlerType, QueryLoader } from '../../loaders';
import { getAbsoluteRoute } from '../../util';
import { getFeaturedProviderOffers } from '../../util/alternatives/get-featured-provider-offers';
import { getProviderOffersByType } from '../../util/provider-offers/provider-offers-utils';
import { usePrescriptionInputVariables } from '../cabinet/prescription/use-prescription-variables';

export const DrugRoute = () => {
    const { primaryPatientId: patientId } = usePatientContext();

    const distance = useNumericSearchParam('within');
    const chosenQuantity = useNumericSearchParam('quantity');
    const daysSupply = useNumericSearchParam('daysSupply');

    const Loader = QueryLoader<typeof GetProductOffersDocument>;
    const { drugId } = useParams();

    const input = usePrescriptionInputVariables(distance, patientId);

    if (!drugId) {
        return <Navigate to={getAbsoluteRoute('error')} />;
    }

    return (
        <Loader
            query={GetProductOffersDocument}
            variables={{
                productId: drugId,
                input: {
                    quantity: chosenQuantity,
                    daysSupply: daysSupply,
                    ...input,
                },
            }}
            component={DataHandler}
        />
    );
};

const DataHandler: DataHandlerType<typeof GetProductOffersDocument> = ({
    data,
}: {
    data: GetProductOffersQuery;
}) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [isDrugConfigOpen, setDrugConfigOpen] = useState(false);

    const searchQuery = searchParams.get('q');

    const handleBack = () => {
        if (searchQuery) {
            navigate({
                pathname: getAbsoluteRoute('drug.search.root'),
                search: createSearchParams({ q: searchQuery }).toString(),
            });
        } else {
            navigate(getAbsoluteRoute('drug.search.root'));
        }
    };

    useConditionalBackOverride(handleBack, !!searchQuery);

    const { telemetryInstance } = useTelemetryContext();
    const product = getFragment(data.product);
    const offersConnection = getFragment(data.product?.offersConnection);

    if (!product || !offersConnection) {
        console.log('error no data');
        telemetryInstance.logError({
            source: 'drug-route',
            error: new Error('no data'),
        });
        return <Navigate to={getAbsoluteRoute('error')} />;
    }
    const providerOffers = offersConnection.edges.map((edge) =>
        getFragment(edge.node)
    );

    const onSelect = (providerOffersId: string, providerId?: string) => {
        const selectedProviderOffers = providerOffers.find(
            (offer) => offer.id === providerOffersId
        );

        const searchParams = providerId
            ? createSearchParams({ providerId }).toString()
            : '';
        //Skip to overview if only one offer option.
        if (
            selectedProviderOffers &&
            selectedProviderOffers.offers.length <= 1
        ) {
            const offerFragment = getFragment(selectedProviderOffers.offers[0]);

            navigate({
                pathname: getAbsoluteRoute('transfer.root', {
                    providerOffersId,
                    offerId: offerFragment?.id ?? 'unknown',
                }),
                search: searchParams,
            });
            return;
        }

        navigate({
            pathname: getAbsoluteRoute('drug.drugId.providerOffers.root', {
                providerOffersId,
                drugId: product.id,
            }),
            search: searchParams,
        });
    };

    const featuredProvider = getFeaturedProviderOffers(offersConnection);

    const providerOfferTypes = getProviderOffersByType(
        offersConnection,
        featuredProvider?.providerOffers.id
    );

    return isDrugConfigOpen ? (
        <ProductConfiguration
            drugId={product.id}
            drugFamilyId={searchParams.get('familyId') ?? undefined}
            closeEdit={() => setDrugConfigOpen(false)}
        />
    ) : (
        <ProviderSelection
            providerOffersByType={providerOfferTypes}
            onSelect={onSelect}
            header={
                <ShoppingHeader>
                    <DrugFamilyHeader
                        drugId={product.id}
                        editClicked={() => {
                            setDrugConfigOpen(true);
                        }}
                    />
                </ShoppingHeader>
            }
            featuredProvider={featuredProvider}
        />
    );
};
