import {
    Body1,
    Heading1,
    Stack,
    Subtitle1,
    TextInput,
} from '@phx/design-system';
import { useGeolocation } from '@phx/location-utils';
import { IllustrationCard, LocationFailedIllustration } from '@phx/myphx-lib';
import type { PropsWithChildren as PWC } from 'react';
import { useTranslation } from 'react-i18next';

import { PhxLocationPicker } from '../../components/cabinet/prescription/PhxLocationPicker';
import { InNetworkPharmacies } from '../../components/pharmacy/InNetworkPharmacies';
import { SearchLocationsDocument } from '../../graphql/generated/graphql';
import { QueryLoader } from '../../loaders';

export const InNetworkPharmaciesRoute = () => {
    const Loader = QueryLoader<typeof SearchLocationsDocument>;
    const { geolocation } = useGeolocation();
    const { t } = useTranslation();

    if (geolocation) {
        return (
            <InNetworkPharmaciesShell>
                <Loader
                    query={SearchLocationsDocument}
                    variables={{
                        searchParams: {
                            connectionPageInput: {
                                first: 50,
                            },
                            includeInNetworkOnly: true,
                            within: {
                                radiusMiles: 100,
                                coordinates: {
                                    latitude: geolocation.latitude,
                                    longitude: geolocation.longitude,
                                },
                            },
                        },
                    }}
                    component={({ data }) => {
                        return (
                            <InNetworkPharmacies
                                locations={data.locations.edges}
                                geolocation={geolocation}
                            />
                        );
                    }}
                />
            </InNetworkPharmaciesShell>
        );
    }

    return (
        <InNetworkPharmaciesShell>
            <PhxLocationPicker />
            <Stack gap="md">
                <TextInput
                    disabled
                    label={t('pharmacy.inNetwork.inputPlaceholder')}
                    value=""
                />
                <IllustrationCard
                    illustrationPosition="top"
                    Illustration={LocationFailedIllustration}
                    illustrationSize="lg"
                    variant="bare"
                    withShadow={false}
                >
                    <Stack gap="xxs">
                        <Subtitle1 ta="center">
                            {t('pharmacy.inNetwork.noLocation.header')}
                        </Subtitle1>
                        <Body1 ta="center">
                            {t('pharmacy.inNetwork.noLocation.body')}
                        </Body1>
                    </Stack>
                </IllustrationCard>
            </Stack>
        </InNetworkPharmaciesShell>
    );
};

const InNetworkPharmaciesShell = ({ children }: PWC) => {
    const { t } = useTranslation();

    return (
        <Stack gap="lg">
            <Stack gap="sm">
                <Heading1>{t('pharmacy.inNetwork.heading')}</Heading1>
                <Body1>{t('pharmacy.inNetwork.subheading')}</Body1>
            </Stack>
            {children}
        </Stack>
    );
};
