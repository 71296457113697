import { jsx as _jsx } from "react/jsx-runtime";
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';
import { AppInsightsContext, ReactPlugin, } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import { createContext, useState } from 'react';
import { TelemetryProviderFactory, } from '../provider';
const reactPlugin = new ReactPlugin();
const browserHistory = createBrowserHistory();
const clickPluginInstance = new ClickAnalyticsPlugin();
// Click Analytics configuration
const clickPluginConfig = {
    autoCapture: true,
};
export const TelemetryContext = createContext(null);
export const TelemetryProvider = ({ children, instrumentationKey, }) => {
    const [instance, _] = useState(TelemetryProviderFactory.getInstance({
        instrumentationKey,
        extensions: [reactPlugin, clickPluginInstance],
        extensionConfig: {
            [clickPluginInstance.identifier]: clickPluginConfig,
            [reactPlugin.identifier]: { history: browserHistory },
        },
    }
    // TODO: build number
    ));
    return (_jsx(TelemetryContext.Provider, { value: { telemetryInstance: instance }, children: _jsx(AppInsightsContext.Provider, { value: reactPlugin, children: children }) }));
};
