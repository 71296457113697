export var KnownErrorCode;
(function (KnownErrorCode) {
    KnownErrorCode["DigitalRxUnclaimed"] = "DigitalRxUnclaimed";
    KnownErrorCode["DigitalRxClaimed"] = "DigitalRxClaimed";
    KnownErrorCode["VerifyDoesNotMatch"] = "VerifyDoesNotMatch";
    KnownErrorCode["Unauthorized"] = "Unauthorized";
    KnownErrorCode["Forbidden"] = "Forbidden";
    KnownErrorCode["InternalServerError"] = "InternalServerError";
    KnownErrorCode["MedicationIsUnclaimed"] = "MedicationIsUnclaimed";
})(KnownErrorCode || (KnownErrorCode = {}));
export const isKnownErrorCode = (code) => {
    return !!KnownErrorCode[code];
};
