import { useMutation, useQuery } from '@apollo/client';
import { useContext } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { Loader } from '../../components/common/Loader';
import { VerifyPrescriptionTransfer } from '../../components/transfer-flow/VerifyPrescriptionTransfer';
import { PatientContext } from '../../contexts/patient/PatientProvider';
import { getFragment, getFragments } from '../../graphql';
import {
    GetPharmacyProviderDocument,
    GetProviderOffersDocument,
    TransferPrescriberOrderDocument,
} from '../../graphql/generated/graphql';
import { getAbsoluteRoute } from '../../util';
export const VerifyTransferRoute = () => {
    const navigate = useNavigate();

    const { primaryPatientId: patientId } = useContext(PatientContext);
    const { locationId, offerId, providerOffersId } = useParams();

    if (!locationId || !providerOffersId || !offerId) {
        return <Navigate to={getAbsoluteRoute('error')} />;
    }

    const { data, loading } = useQuery(GetProviderOffersDocument, {
        variables: { id: providerOffersId },
    });

    const { data: toLocationData, loading: toLocationLoading } = useQuery(
        GetPharmacyProviderDocument,
        {
            variables: { id: locationId },
        }
    );

    const [TransferPrescription, { loading: transferLoading }] = useMutation(
        TransferPrescriberOrderDocument
    );

    if (loading || toLocationLoading) {
        return <Loader />;
    }

    const providerOffers = getFragment(data?.providerOffers);
    const selectedOffer = getFragments(providerOffers?.offers)?.find(
        (offer) => offer.id === offerId
    );
    const fromLocation = getFragment(toLocationData?.pharmacyProvider);

    if (!selectedOffer || !providerOffers || !fromLocation) {
        return <Navigate to={getAbsoluteRoute('error')} />;
    }
    const product = getFragment(providerOffers.product);
    const toLocation = getFragment(selectedOffer.pharmacyProvider);
    const providerOfferDaysSupply =
        selectedOffer?.daysSupply ?? product?.modeQuantity ?? 30;
    const providerOfferQuantity =
        selectedOffer?.quantity ?? product?.modeDaysSupply ?? 30;

    return (
        <VerifyPrescriptionTransfer
            from={fromLocation}
            to={toLocation}
            offer={selectedOffer}
            transferLoading={transferLoading}
            onTransferPrescription={(prescriptionId) => {
                TransferPrescription({
                    variables: {
                        sourcePharmacy: fromLocation.id,
                        destinationPharmacy: toLocation.id,
                        product: {
                            productId: providerOffers.product.id,
                            daysSupply: providerOfferDaysSupply,
                            quantity: providerOfferQuantity,
                        },
                        patientId,
                        rxNumber: prescriptionId,
                    },
                    onCompleted: (data) => {
                        if (data.transferPrescriberOrder.success) {
                            navigate(
                                getAbsoluteRoute(
                                    'transfer.location.verify.submitted',
                                    {
                                        providerOffersId: providerOffersId,
                                        offerId: offerId,
                                        locationId: locationId,
                                    }
                                ),
                                {
                                    state: {
                                        prescriptionId,
                                        offer: selectedOffer,
                                    },
                                }
                            );
                        }
                    },
                });
            }}
        />
    );
};
