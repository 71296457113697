import { useTelemetryContext } from '@phx/instrumentation/client';
import {
    LocationPicker,
    useGeolocation,
    useLocationService,
} from '@phx/location-utils';

const accessToken = import.meta.env.VITE_MAPBOX_ACCESS_TOKEN;

export const PhxLocationPicker = (
    props: Omit<
        Parameters<typeof LocationPicker>[0],
        'locationService' | 'onLocationChange' | 'geolocation'
    >
) => {
    const { telemetryInstance } = useTelemetryContext();
    const { geolocation, setGeolocation } = useGeolocation();
    const locationService = useLocationService(accessToken, telemetryInstance);
    return (
        <LocationPicker
            locationService={locationService}
            onLocationChange={setGeolocation}
            geolocation={geolocation}
            {...props}
        />
    );
};
