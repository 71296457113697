import {
    Body2,
    Box,
    Carousel,
    ElevatedCard,
    FilledCard,
    Flex,
    Heading1,
    Stack,
} from '@phx/design-system';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useSwipeableViews } from '../../hooks/use-swipeable-views';

export const Testimonials = () => {
    const { t } = useTranslation();

    const testimonials = useMemo(() => {
        const items = t('unauth.testimonials.items', {
            returnObjects: true,
        }) as Array<{ content: string }>;

        if (!Array.isArray(items)) {
            return [];
        }

        return items.map((item) => ({
            id: item.content,
            content: (
                <Body2>
                    {'“'}
                    {item.content}
                    {'”'}
                </Body2>
            ),
        }));
    }, [t]);

    const useCarousel = useSwipeableViews(testimonials, 'md');

    return (
        <Box pb="xl">
            <FilledCard color="gray">
                <Stack gap="md" pt="xxxl" pb="xl">
                    <Heading1>{t('unauth.testimonials.header')}</Heading1>
                    {useCarousel ? (
                        <Carousel
                            cropped
                            indicatorOffset="calc(var(--mantine-spacing-lg) * -1)"
                            slides={testimonials.map((testimonial) => (
                                <ElevatedCard key={testimonial.id}>
                                    {testimonial.content}
                                </ElevatedCard>
                            ))}
                        />
                    ) : (
                        <Flex gap="md">
                            {testimonials.map((testimonial) => (
                                <ElevatedCard key={testimonial.id}>
                                    {testimonial.content}
                                </ElevatedCard>
                            ))}
                        </Flex>
                    )}
                </Stack>
            </FilledCard>
        </Box>
    );
};
