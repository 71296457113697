import {
    Body1,
    Button,
    Cover,
    Heading1,
    Heading2,
    Slider,
    Stack,
    useNavBar,
} from '@phx/design-system';
import { useTelemetryContext } from '@phx/instrumentation/client';
import {
    LocationPicker,
    useGeolocation,
    useLocationService,
} from '@phx/location-utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { useNumericSearchParam } from '../../hooks/use-numeric-search-param';

const accessToken = import.meta.env.VITE_MAPBOX_ACCESS_TOKEN;
const MIN_SLIDER_VALUE = 1;
const MAX_SLIDER_VALUE = 100;

type LocationPickerCoverProps = {
    isOpen: boolean;
    onClose: () => void;
};

export const LocationPickerCover = ({
    isOpen,
    onClose,
}: LocationPickerCoverProps) => {
    const { setBackOverrideState } = useNavBar();

    const { t } = useTranslation();
    const { telemetryInstance } = useTelemetryContext();

    const { geolocation, setGeolocation } = useGeolocation();
    const [newGeolocation, setNewGeolocation] = useState(geolocation);
    const [hasError, setHasError] = useState<boolean>(false);
    const [, setSearchParams] = useSearchParams();

    const initialDistance = useNumericSearchParam('within');
    const [distance, setDistance] = useState<number>(initialDistance);

    const handleSetLocation = () => {
        setGeolocation(newGeolocation);
        setSearchParams((previous) => {
            previous.set('within', distance.toString());

            return previous;
        });

        onClose();
    };

    const locationService = useLocationService(accessToken, telemetryInstance);

    useEffect(() => {
        if (!isOpen) {
            return;
        }

        setBackOverrideState({
            overrideFn: onClose,
        });

        return () => setBackOverrideState({ overrideFn: null });
    }, [isOpen, onClose]);

    return (
        <Cover visible={isOpen}>
            <Stack gap="lg" flex="1">
                <Stack gap="sm">
                    <Heading1>{t('locationPickerCover.title')}</Heading1>
                    <Body1>{t('locationPickerCover.subtitle')}</Body1>
                </Stack>
                <Stack gap="xl">
                    <Stack gap="sm">
                        <Heading2>
                            {t('locationPickerCover.subheader')}
                        </Heading2>
                        <LocationPicker
                            locationService={locationService}
                            onLocationChange={setNewGeolocation}
                            geolocation={geolocation}
                            autofill={false}
                            setError={() => setHasError(hasError)}
                        />
                    </Stack>
                    <Stack gap="xxxl">
                        <Stack gap="xxxl">
                            <Heading2>
                                {t('locationPickerCover.distanceRange')}
                            </Heading2>

                            <Slider
                                disabled={!newGeolocation || hasError}
                                min={MIN_SLIDER_VALUE}
                                max={MAX_SLIDER_VALUE}
                                minLabel={t(
                                    'common.distance.distanceWithUnit',
                                    { miles: MIN_SLIDER_VALUE }
                                )}
                                maxLabel={t(
                                    'common.distance.distanceWithUnit',
                                    { miles: MAX_SLIDER_VALUE }
                                )}
                                value={distance}
                                onChange={(value) => {
                                    if (!hasError) {
                                        setDistance(value);
                                    }
                                }}
                                marks={[20, 40, 60, 80]}
                            />
                        </Stack>
                        <Button
                            size="lg"
                            disabled={!newGeolocation || hasError}
                            onClick={handleSetLocation}
                        >
                            {t('common.cover.showPharmacies')}
                        </Button>
                    </Stack>
                </Stack>
            </Stack>
        </Cover>
    );
};
