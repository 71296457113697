import { getFragment } from '../../graphql';
import type {
    CashOfferInfoFragment,
    InsuranceOfferInfoFragment,
} from '../../graphql/generated/graphql';

export const getOffersByProviderId = (
    offers: Array<CashOfferInfoFragment | InsuranceOfferInfoFragment>,
    providerId: string
) =>
    offers.filter((offer) => {
        const provider = getFragment(offer.pharmacyProvider);
        return provider.id === providerId;
    });
