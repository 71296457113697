import { Flex, TextButton, modals } from '@phx/design-system';
import { useDisclosure } from '@phx/design-system/hooks';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import type { PharmacyProviderInfoFragment } from '../../../graphql/generated/graphql';

import { MoreProvidersDialog } from './MoreProvidersDialog';

export const MoreProviders = ({
    primaryName,
    moreProviders,
    onSelected,
}: {
    primaryName: string;
    moreProviders: PharmacyProviderInfoFragment[];
    onSelected: (providerId: string) => void;
}) => {
    if (!moreProviders.length) {
        return null;
    }
    const [dialogOpen, { open, close }] = useDisclosure(false);
    const { t } = useTranslation();

    const selectedLocation = useCallback(
        (id: string) => {
            onSelected(id);
            modals.closeAll();
        },
        [onSelected]
    );

    return (
        <Flex
            align="flex-start"
            onClick={(event: React.MouseEvent) => {
                // Must stop event propagation as entire card is clickable
                event.stopPropagation();
            }}
        >
            <TextButton
                onClick={(event: React.MouseEvent) => {
                    event.stopPropagation();
                    open();
                }}
            >
                {t('offerPharmacyCard.showMoreProviders', {
                    count: moreProviders.length,
                })}
            </TextButton>
            <MoreProvidersDialog
                title={primaryName}
                isOpen={dialogOpen}
                close={close}
                moreProviders={moreProviders}
                onSelected={selectedLocation}
            />
        </Flex>
    );
};
