import { Box } from '@phx/design-system';
import type React from 'react';

import classes from './shopping-header.module.css';

export const ShoppingHeader = (props: React.PropsWithChildren) => {
    const { children } = props;
    return (
        <Box className={classes['shopping-header']}>
            {children}
            <img
                src="/static/shopping-header/myphx-pattern.svg"
                alt=""
                height="120"
                width="120"
            />
        </Box>
    );
};
