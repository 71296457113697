import { Outlet, type RouteObject } from 'react-router-dom';

import routes from '../app-routes';

import Faq from './help-center-components/faq/FaqTemplate';
import WhatsNewRoute from './help-center-components/whats-new/WhatsNewRoute';
import HelpCenterRoute from './HelpCenterRoute';
import HowToSendDrxRoute from './HowToSendDrxRoute';

const helpCenterRoutes: RouteObject[] = [
    {
        path: routes.helpCenter.root,
        element: <Outlet />,
        children: [
            {
                index: true,
                element: <HelpCenterRoute />,
            },
            {
                path: routes.helpCenter.sendrx,
                element: <HowToSendDrxRoute />,
            },
            {
                path: routes.helpCenter.whatsnew.root,
                element: <Outlet />,
                children: [
                    {
                        index: true,
                        element: <WhatsNewRoute />,
                    },
                    //Adding what's new announcements here
                ],
            },
            {
                path: routes.helpCenter.about,
                element: <Faq section="about" />,
                id: 'about',
            },
            {
                path: routes.helpCenter.settings,
                element: <Faq section="settings" />,
                id: 'settings',
            },
            {
                path: routes.helpCenter.employer,
                element: <Faq section="employer" />,
                id: 'employer',
            },
            {
                path: routes.helpCenter.prescriptionManagement,
                element: <Faq section="prescriptionManagement" />,
                id: 'prescriptionManagement',
            },
            {
                path: routes.helpCenter.familyCare,
                element: <Faq section="familyCare" />,
                id: 'familyCare',
            },
            {
                path: routes.helpCenter.costsAndSavings,
                element: <Faq section="costsAndSavings" />,
                id: 'costsAndSavings',
            },
            {
                path: routes.helpCenter.privacySecurity,
                element: <Faq section="privacySecurity" />,
                id: 'privacySecurity',
            },
        ],
    },
];

export default helpCenterRoutes;
