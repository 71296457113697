import { useAuthContext } from '@phx/auth/client';
import { AppShell, useNavBar } from '@phx/design-system';
import { PwaOverlays } from '@phx/pwa';
import { useMemo } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { useSidebarContext } from '../../contexts/SidebarProvider';
import { usePatientContext } from '../../hooks/use-patient-context';
import { getAbsoluteRoute } from '../../util';
import { Footer } from '../unauth-home/Footer';

export const AppContent = () => {
    const { isAuthenticated } = useAuthContext();
    const { toggle: toggleSidebar } = useSidebarContext();
    const navigate = useNavigate();
    const { backOverrideState, backButtonDisabled } = useNavBar();
    const { pathname, key } = useLocation();
    const { patient } = usePatientContext();
    const homeUrl = isAuthenticated
        ? getAbsoluteRoute('home')
        : getAbsoluteRoute('root');
    const isHome = pathname === homeUrl;
    const isRoot = pathname === getAbsoluteRoute('root');

    const goHome = useMemo(() => {
        if (isHome) return;

        return () => navigate(homeUrl);
    }, [homeUrl, pathname, navigate]);

    const handleBack = useMemo(() => {
        // Explanation here https://github.com/remix-run/history/blob/main/docs/api-reference.md#locationkey
        const isInitialLocation = key === 'default';

        if (backOverrideState.overrideFn) return backOverrideState.overrideFn;
        if (isHome || isInitialLocation) return;

        return () => navigate(-1);
    }, [pathname, backOverrideState.overrideFn]);

    // 1024px for the landing page, fall back to the default everywhere else
    const maxWidth = isRoot ? '1024px' : undefined;

    return (
        <AppShell
            maxWidth={maxWidth}
            navBarProps={{
                logoUrl: '/static/img/myprescryptive-logo.png',
                onBackButtonClick: handleBack,
                onLogoClick: goHome,
                onAvatarClick: toggleSidebar,
                auth: isAuthenticated,
                username: patient?.fullName,
                hideBackButton: backButtonDisabled,
            }}
            // For now, we only show the footer on the unauth home screen, but we will likely show it across the app in the future
            footer={isRoot ? <Footer /> : null}
            footerBg="var(--mantine-color-primary-9)"
        >
            <Outlet />
            <PwaOverlays />
        </AppShell>
    );
};
